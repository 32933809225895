import React from 'react';
import ReactDOM from 'react-dom';
import zhCN from 'antd/lib/locale/zh_CN';
import 'moment/locale/zh-cn';
import { HashRouter, Route, Switch } from 'react-router-dom';
import { parse } from 'qs';
import { ConfigProvider } from 'antd';
import reportWebVitals from './reportWebVitals';
import App from './App';
import { login, queryUserRole } from './api';
import { LOGIN_BROWSER_ID, LOGIN_TOKEN_KEY, LOGIN_USER_ROLE, ROLE_ADMIN, ROLE_COMMODITY_SELECT, ROLE_EMPLOYEE, ROLE_EMPLOYEE_SENIOR_SHIPPER, ROLE_GUEST, ROLE_OPERATOR, ROLE_SENIOR_ADMIN, ROLE_SENIOR_ADMIN_ADD, ROLE_SENIOR_EMPLOYEE, ROLE_SENIOR_SHIPPER, ROLE_SHIPPER, ROLE_UN_DEVICE, ROLE_WITHDRAW, ROLE_UN_LOGIN, ROLE_HIGH_GUEST } from './consts';
import UnAuth from './pages/403';
import { USER_ROLE_ADMIN, USER_ROLE_COMMODITY_SELECT, USER_ROLE_EMPLOYEE, USER_ROLE_EMPLOYEE_SENIOR_SHIPPER, USER_ROLE_GUEST, USER_ROLE_HIGH_GUEST, USER_ROLE_OPERATOR, USER_ROLE_SENIOR_ADMIN, USER_ROLE_SENIOR_ADMIN_ADD, USER_ROLE_SENIOR_EMPLOYEE, USER_ROLE_SENIOR_SHIPPER, USER_ROLE_SHIPPER, USER_ROLE_WITHDRAW } from './api/consts';
import { createFingerprint } from './utils/fingerprint';
import UnknownComputer from './pages/402';

const login2Server = async () => {
  const searchParams = parse(window.location.search.replace('?', ''));
  if (searchParams.code) {
    const res = await login(searchParams.code as string);
    const { Token } = res.data.data;
    sessionStorage.setItem(LOGIN_TOKEN_KEY, Token);
    window.location.href = '/';
    sessionStorage.removeItem('tagItem')
  }
};

(async () => {
  const user_role: Record<string, string> = {
    [USER_ROLE_SENIOR_ADMIN]: ROLE_SENIOR_ADMIN,
    [USER_ROLE_ADMIN]: ROLE_ADMIN,
    [USER_ROLE_GUEST]: ROLE_GUEST,
    [USER_ROLE_EMPLOYEE]: ROLE_EMPLOYEE,
    [USER_ROLE_SENIOR_EMPLOYEE]: ROLE_SENIOR_EMPLOYEE,
    [USER_ROLE_SHIPPER]: ROLE_SHIPPER,
    [USER_ROLE_SENIOR_SHIPPER]: ROLE_SENIOR_SHIPPER,
    [USER_ROLE_EMPLOYEE_SENIOR_SHIPPER]: ROLE_EMPLOYEE_SENIOR_SHIPPER,
    [USER_ROLE_COMMODITY_SELECT]: ROLE_COMMODITY_SELECT,
    [USER_ROLE_OPERATOR]: ROLE_OPERATOR,
    [USER_ROLE_SENIOR_ADMIN_ADD]: ROLE_SENIOR_ADMIN_ADD,
    [USER_ROLE_WITHDRAW]: ROLE_WITHDRAW,
    [USER_ROLE_HIGH_GUEST]: ROLE_HIGH_GUEST,
    'unLogin': ROLE_UN_LOGIN,
    'unDevice': ROLE_UN_DEVICE
  }
  if (!localStorage.getItem(LOGIN_BROWSER_ID)) createFingerprint()
  await login2Server();
  let role = await queryUserRole();
  // let role = '00000';
  // if(!user_role[role]) role = USER_ROLE_GUEST;
  if (role === USER_ROLE_GUEST && sessionStorage.getItem('token')) window.location.hash = '/403';

  sessionStorage.setItem(LOGIN_USER_ROLE, user_role[role]);
  //  {/* <React.StrictMode> </React.StrictMode>, */}
  ReactDOM.render(

    <HashRouter basename='pages'>
      <ConfigProvider locale={zhCN}>
        <Switch>
          {
            role === USER_ROLE_GUEST ?
              (<Route exact path='/403' component={UnAuth} />) :
              role === ROLE_UN_DEVICE ?
                (<Route exact path='/402' component={UnknownComputer} />) :
                (<Route path='/' component={App} />)
          }
        </Switch>
      </ConfigProvider>
    </HashRouter>,

    document.getElementById('root')
  );
  // If you want to start measuring performance in your app, pass a function
  // to log results (for example: reportWebVitals(console.log))
  // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
  reportWebVitals();
})();
