import React from 'react';

import './index.less';

export default function UnAuth () {
  return (
    <div className='pg-unauth'>
      <h1>滚，你没有权限访问！！！</h1>
    </div>
  );
}