import { ArticleData } from './../../types/forum.d';
import { Category } from '../../types/category';
import {
  CategoryPO,
  Pagination,
  ParamsQueryByCategory,
  ProductSpecDTO,
  queryProductCategoryListParams,
  IntegrationProductPo,
  DocumentPo,
  GiveGiftsRecordPo,
  UnlimitedDrawDefineProduct,
  AddUnlimitedDrawDefineDTO,
  ProductTransferRecordsDTO,
  UserRealTimeAmountInterfaceDTO,
  ChefListFileListPo,
  ChefListFileListDto,
  SaveRankingsPrizePoolDTO,
  AddOrUpdatePointsDrawDefineDTO,
  PointsDrawDefineProduct,
  GiftUserFileListPo,
  GiftUserFileListDTO,
  saveSignInRulesConfigDTO,
  ProfitLossDTO,
  AuditListPo,
  UserRecyclingRecordsDTO,
  SaveItemPoolDTO,
  ItemPoolProductDTO,
  SaveHiddenPoolDTO,
  PrizeRecordsPo,
  UserAddressPo,
  ExchangCodeListPo,
  BatchListingPo,
  SaveBatchListingPo,
  OrderNotYetShippedGoodPo,
  UserCollectionRecordsPo,
  SelectRankingRewardDTO,
  SaveRankingRewardDTO,
  SaveRankingRewards,
  SelectRankingRewardIDDTO,
  SaveRankingRewardIDs,
  ShovelRecordsPo,
  ProductEditRecordsPo,
  RelatedTemplatPo,
  AllConditionDTO,
  ConditionDefineDTO,
  ConditiondproductList,
  HiddenDefineProduct,
  leaderboardStageRewardDTOList,
  AddPagodaDrawDefineDTO,
  TowerGameDefineRelDTO,
  RewardListItem,
  CardDetailListItem,
  CardData,
  WelfareCardRecordListItem,
  TransformWelfareCardRecordListItem,
  WelfareCardNameListItem,
  OptionType,
  VipGradeListDTO,
  QueryActivityListItem,
  TransformQueryActivityListItem,
  QueryActivityByIdDetail,
  TransformQueryActivityByIdDetail,
  QueryActivityByIdDtlDTOListItem,
  TransformQueryActivityByIdDtlDTOListItem,
  TransformQueryActivityParams,
  QueryActivityParams,
  UserGuideListDTO,
  UserGuidedetailList,
  SaveCubePoolDTO,
  relDTO,
  CobeCompoundListPo,
  CubeRecordDTO,
  OrderDetail,
  BigPrizeListItem,
  TowerFloorRecordList,
  AddressPo,
  AdministrativeDivisionItem,
  productImgDetailsPo,
  hiddenPostConfigPo,
  ConfigGivenPo,
} from './index.d';

import {
  PRODUCT_BASE_CATEGORY_ID_IP,
  PRODUCT_BASE_CATEGORY_ID_CATEGORY,
  PRODUCT_BASE_CATEGORY_ID_TYPE,
  GAME_BASE_CATEGORY_ID_GRADE,
  TOPUP_SOURCE_DISPLAY_MAPPING,
  TOPUP_PAYMENT_STATE_DISPLAY_MAPPING,
  WITHDRAW_STATE_DISPLAY_MAPPING,
  BUSINESS_TYPE_DISPLAY_MAPPING,
  RECORD_TYPE_DISPLAY_MAPPING,
  CDN_DOMAIN,
  GARAGE_KIT_LOCK,
} from './consts';
import {
  CubePoolProduct,
  CubePoolsSepc,
  ItemPoolProduct,
  ItemPoolsSepc,
  MainItemPool,
  Product4PointsSepc,
  ProductSpec,
} from '../../types/product';
import { UploadFile } from 'antd/lib/upload/interface';
import {
  AmountRecordsPo,
  ApplyForDeliveryPo,
  DailyStatisticsItem,
  GameDefinePo,
  GamePo,
  GameProductPo,
  GameRecordsPo,
  KnapsackAllDailyStatisticsDTO,
  KnapsackAllStatisticsDTO,
  KnapsackDailyStatisticsByAccountDTO,
  KnapsackPo,
  OrderPo,
  PosterDocumentPo,
  PosterListPo,
  UserDTO,
  VipLevelCollocationPo,
  WithdrawRecordsPo,
} from '.';
import { gameTypesOptions } from '../pages/game/GameDefine/consts';
import { gameTypesOptions as infinityGameTypesOptions } from '../pages/game/InfinityGameDefine/consts';
import {
  Game,
  GameBox,
  LimitedGameDefine,
  LimitedGameProduct,
  GameType,
  InfinityGameProduct,
  InfinityGameDefine,
  PointsDrawDefine,
  PointsDrawProduct,
  HiddenPoolGame,
  SaveBatchListings,
} from '../../types/game';
import { CheckProductInfo, DeliveryOrder, TransferRecord } from '../../types/order';
import { Key } from 'react';
import {
  BillsRecord,
  DailyKnapsackStatistic,
  GameRecord,
  GarageKit,
  KnapsackStatistic,
  PrizeRecords,
  ProfitLosss,
  ProductEditRecords,
  ShovelRecords,
  TopupRecord,
  UserCollectionRecords,
  UserKnapsackStatistic,
  UserRealTimeAmountInterface,
  UserRecyclingRecords,
  VipMember,
  WithdrawRecord,
  VipGrade,
} from '../../types/vip';
import {
  AddRankingReward,
  Address,
  AwardsSetting,
  GiftManageFile,
  GiftManageFileList,
  GiveGiftRecord,
  KitchenGod,
  KitchenGodFileList,
  Poster,
  RankingList,
  RankingPoster,
  RankingRewards,
  SelectRankingRewarIDwebs,
  SelectRankingRewars,
  SignInManagePoster,
} from '../../types/system';
import { fromPairs, isBoolean, isNumber } from 'lodash-es';
import {
  transform2ServerProbability,
  transformServerProbability,
} from '../utils/calculate';
import { gameStatesOptions } from '../pages/system/RankingListManage/consts';
import { integralRewardTypesOptions } from '../pages/game/IntegralReward/consts';
import moment from 'moment';
import { ArticleReviewAudit } from '../../types/audit';
import { formatDate, formatDatetime } from '../utils/datetimeUtils';
import { VoucherDefine } from '../../types/voucher';
import {
  MODE_CONDITION,
  MODE_RANGE,
  MODE_SPECIFIC,
  MODE_TOP,
  REWARD_COMMODITY,
  REWARD_INTEGRAL,
  REWARD_MEALTICKET,
  REWARD_MONEY,
  REWARD_TOKEN,
} from '../pages/system/RankingReward/consts';
import { mealticketGameTypesOptions } from '../pages/game/MealticketGame/consts';
import {
  IntegralTypeOption,
  MealticketTypeOptions,
} from '../pages/product/ProductEdit/components/relatedTemplate/consts';
import { AddPagodaReward, PagodaRewards } from '../../types/pagoda';
import {
  AddressList,
  StockListDefine,
  StockRecordDefine,
} from '../../types/stockControl';
import {
  RewardType,
  tranformCardDetailList,
  tranformCardListItem,
  tranformRewardList,
} from '../../types/weekCard';
import { stateType } from '../pages/system/PeriodicActivityManage/consts';
import { computeNumber } from '../utils/computeNumber';

export const transformServerAmount = (amount: number) => computeNumber(amount,'/',100).result;

export const transform2ServerAmount = (amount: number) =>
computeNumber(amount,'*',100).result;

export const returnOriginalUri = (uri: string) => {
  return (uri || '').replace(
    `${CDN_DOMAIN}/mwUpload`,
    '/api/upload-resources'
  )
};

export const transformUri = (uri: string) => {
  var Expression=/http(s)?:\/\/([\w-]+\.)+[\w-]+(\/[\w- .\/?%&=]*)?/;
  var objExp=new RegExp(Expression);
  if(objExp.test(uri)===true){
    return uri
  }else{
    return `${CDN_DOMAIN}${(uri || '').replace('/api/upload-resources',window.location.host === 'test.cookactoys.cn'?  '/upload' : '/mwUpload')}`;
  }
  // return `${CDN_DOMAIN}${(uri || '').replace(
  //   '/api/upload-resources',
  //   '/mwUpload'
  // )}`;
};

export const transformForumUri = (uri: string) => {
  var Expression=/http(s)?:\/\/([\w-]+\.)+[\w-]+(\/[\w- .\/?%&=]*)?/;
  var objExp=new RegExp(Expression);
  if(objExp.test(uri)===true){
    return uri
  }else{
    return `${CDN_DOMAIN}${(uri || '').replace('/api/upload-resources','/mwUserUpload')}`;
  }
  // return `${CDN_DOMAIN}${(uri || '').replace(
  //   '/api/upload-resources',
  //   '/mwUserUpload'
  // )}`;
};

export const transform2CategoryPO = (category: Category) => {
  const { id, name, parentCategory,imgFile, } = category;
  if (!name) throw new Error(`category必填字段为空，name:${name}, id:${id}`);
  const { uid, uri } = imgFile || {}
  const productCategoryPO: CategoryPO = {
    id,
    superCategoryId: parentCategory?.id,
    name: name,
    superCategoryName: parentCategory?.name,
    documentId: uid,
    uri,
  };
  return productCategoryPO;
};

export const transfromCategoryPo = (po: CategoryPO) => {
  const {
    id,
    superCategoryId,
    name,
    superCategoryName,
    createdBy,
    createdTime,
    documentId,
    uri,
  } = po || {};
  const catgegory: Category = {
    id,
    name,
    createdBy,
    createdTime,
    imgFile: (documentId && uri) ? {
      name: '',
      uid: documentId,
      url: transformUri(uri),
    } : undefined,
  };
  if (superCategoryId)
    catgegory.parentCategory = {
      id: superCategoryId,
      name: superCategoryName || '',
    };
  return catgegory;
};

export const transformProductSpecDTO = (dto: ProductSpecDTO): ProductSpec => {
  const {
    productSpecId,
    productId,
    documentId,
    productCategoryList = [],
    name,
    description,
    price,
    recoverPrice,
    abandonState,
    count,
    uri, // todo 图片最好把url，uid和name都给我
    saleTime,
    topStatus,
    limitBuyDeductFlag,
    limitBuyDeductNum,
    limitBuyFlag,
    limitBuyNum,
    amount,
    allowRecycling,


    saleEndTime,
    saleStartTime,
    limitCount,
    status,
    goodDetails,
    orderPrice,
  } = dto;
  const categories = productCategoryList
    .filter((po) => po.superCategoryId === PRODUCT_BASE_CATEGORY_ID_CATEGORY)
    .map((po) => {
      return transfromCategoryPo(po);
    });

  const [ip] = productCategoryList
    .filter((po) => po.superCategoryId === PRODUCT_BASE_CATEGORY_ID_IP)
    .map((po) => {
      return transfromCategoryPo(po);
    });

  const [type] = productCategoryList
    .filter((po) => po.superCategoryId === PRODUCT_BASE_CATEGORY_ID_TYPE)
    .map((po) => {
      return transfromCategoryPo(po);
    });
  return {
    imgFiles: (goodDetails || []).map((item) => transformPosterDocumentPo(item)),
    status,
    limitCount,
    saleEndTime, 
    saleStartTime,
    allowRecycling: allowRecycling ? true : false,
    limitBuyDeductFlag: limitBuyDeductFlag ? true : false,
    limitBuyDeductNum,
    limitBuyFlag: limitBuyFlag ? true : false,
    limitBuyNum,
    id: productSpecId,
    productId,
    categories,
    name,
    ip,
    type,
    imgFile:
      documentId && uri
        ? {
            name: '',
            uid: documentId,
            url: transformUri(uri),
          }
        : undefined,
    count,
    price: transformServerAmount(price || amount || 0),
    recoverPrice: transformServerAmount(recoverPrice || 0),
    saleTime: saleTime ? formatDatetime(saleTime as number) : '',
    // @ts-ignore
    expire: saleTime ? saleTime < new Date().valueOf() : false,
    preSellExpire: saleTime ? Number(saleTime) < new Date().valueOf() : true, // 预售
    isAbandon: abandonState === '2' ? true : false, // 1未废弃  2废弃
    abandonState: abandonState === '2' ? '废弃' : '未废弃', // 1未废弃  2废弃
    topStatus,
    orderPrice:transformServerAmount(orderPrice || 0)
  };
};

export const transform2ParamsQueryByCategory = (
  params: queryProductCategoryListParams
) => {
  const { name, parentCategoryId, pageNo, pageSize } = params;

  const params2Req: ParamsQueryByCategory = {
    name: name || undefined,
    superCategoryId: parentCategoryId || undefined,
    pageNo,
    pageSize,
  };
  return params2Req;
};

export const transfrom2ProductSpecDTO = (productSpec: ProductSpec) => {
  const {
    id,
    productId,
    categories,
    name,
    ip,
    type,
    imgFile,
    count,
    price,
    recoverPrice,
    integral,
    saleTime,
    limitBuyDeductFlag,
    limitBuyDeductNum,
    limitBuyFlag,
    limitBuyNum,
    allowRecycling,


    saleStartTime,
    saleEndTime,
    orderPrice,
    limitCount,
    goodDetails,
  } = productSpec;

  const { uid, url } = imgFile || {};
  const productCategoryList: CategoryPO[] = (categories || [])
    .concat(ip && type ? [ip, type] : [])
    .map((category) => {
      return transform2CategoryPO(category);
    })
    .filter((item) => item) as CategoryPO[];

  return {
    allowRecycling: allowRecycling ? 1 : 0,
    limitBuyDeductFlag: limitBuyDeductFlag ? 1 : 0,
    limitBuyDeductNum,
    limitBuyFlag: limitBuyFlag ? 1 : 0,
    limitBuyNum,
    productSpecId: id || undefined,
    productId,
    documentId: uid,
    productCategoryList: productCategoryList,
    name,
    price: transform2ServerAmount(price || 0),
    recoverPrice: transform2ServerAmount(recoverPrice || 0),
    integral: transform2ServerAmount(integral || 0),
    count,
    uri: url,
    saleTime,

    orderPrice: transform2ServerAmount(orderPrice || 0),
    limitCount,
    goodDetails,
    saleEndTime,
    saleStartTime,
  };
};

export type ListTransformer<T, U> = (list: T[]) => U[];

export const transfromPagination = <T, U>(
  pagination: Pagination<T>,
  listTransformer: ListTransformer<T, U>
) => {
  const { list, totalSize } = pagination || {};
  const uList = listTransformer(list);
  return {
    list: uList,
    totalSize,
  };
};

export const transformDocumentPo = (po: DocumentPo): UploadFile<DocumentPo> => {
  const { id, uri, data } = po;
  return {
    uid: id || data || '',
    name: transformUri(uri),
    url: transformUri(uri),
  };
};

export const transformIntegrationProductPo = (
  po: IntegrationProductPo
): Product4PointsSepc => {
  const { id, amount, productSpecId } = po;
  const productSpec = transformProductSpecDTO(po);
  return {
    ...productSpec,
    id,
    productSpecId,
    points: amount,
  };
};

export const transform2IntegrationProductPo = (
  product4PointsSpec: Product4PointsSepc
): IntegrationProductPo => {
  const productSepcDTO = transfrom2ProductSpecDTO(product4PointsSpec);
  const {
    id,
    productSpecId,
    points,
    recoverPrice,
    limitBuyDeductFlag,
    limitBuyFlag,
    allowRecycling,
  } = product4PointsSpec;
  if (points) {
    return {
      ...productSepcDTO,
      allowRecycling: allowRecycling ? 1 : 0,
      limitBuyDeductFlag: limitBuyDeductFlag ? 1 : 0,
      limitBuyFlag: limitBuyFlag ? 1 : 0,
      id,
      productSpecId,
      amount: points,
      recoverPrice,
    };
  } else {
    throw new Error(`product4PointsSpec中points字段为空！`);
  }
};

export const transformGameProductPo = (
  gameProductPo: GameProductPo
): LimitedGameProduct => {
  const {
    id,
    documentId,
    uri,
    productSpecId,
    gameDefineId,
    count,
    award,
    gameCategoryList = [],
    recoverPrice,
    productName,
    name,
  } = gameProductPo;

  const [gradeCatgetoryPo] = (gameCategoryList || []).filter(
    (item) => item.superCategoryId === GAME_BASE_CATEGORY_ID_GRADE
  );

  const grade = transfromCategoryPo(gradeCatgetoryPo);
  return {
    name: productName || name,
    id,
    imgFile:
      documentId && uri
        ? { uid: documentId, url: transformUri(uri), name: documentId }
        : undefined,
    productSpecId: productSpecId,
    grade,
    count,
    rewardPoints: award,
    recoverPrice: transformServerAmount(recoverPrice || 0),
  };
};

export const transformUnlimitedDrawDefineProduct = (
  gameProductPo: UnlimitedDrawDefineProduct
): InfinityGameProduct => {
  const {
    id,
    documentId,
    uri,
    categoryId,
    productSpecId,
    gameDefineId,
    award,
    gameCategoryList = [],
    probability,
    recoverPrice,
    mineDisasterProbability,
    mineDisasterAdvancedProbability,
    quantity,
    productName,
    shovelFlag,
    clearanceFlag,
    backwardFirstFlag,
    backwardFlag,
  } = gameProductPo;

  const [gradeCatgetoryPo] = (gameCategoryList || []).filter(
    (item) => item.id === categoryId
  );

  const grade = transfromCategoryPo(gradeCatgetoryPo);
  let unconventionalitysFlag = 0;
  if (clearanceFlag) {
    unconventionalitysFlag = 1;
  } else if (backwardFlag) {
    unconventionalitysFlag = 2;
  } else if (backwardFirstFlag) {
    unconventionalitysFlag = 3;
  } else if (shovelFlag) {
    unconventionalitysFlag = 4;
  }
  return {
    unconventionalitysFlag,
    clearanceFlag: clearanceFlag ? true : false,
    shovelFlag: shovelFlag ? true : false,
    name: productName,
    id,
    imgFile:
      documentId && uri
        ? { uid: documentId, url: transformUri(uri), name: documentId }
        : undefined,
    productSpecId: productSpecId,
    grade,
    recoverPrice: transform2ServerProbability(recoverPrice || 0),
    probability: isNumber(probability)
      ? transformServerProbability(probability)
      : undefined,
    mineDisasterProbability: isNumber(mineDisasterProbability)
      ? transformServerProbability(mineDisasterProbability)
      : undefined,
    mineDisasterAdvancedProbability: isNumber(mineDisasterAdvancedProbability)
      ? transformServerProbability(mineDisasterAdvancedProbability)
      : undefined,
    rewardPoints: award,
    quantity,
  };
};

export const transformRankingListManageProduct = (
  gameProductPo: UnlimitedDrawDefineProduct
): InfinityGameProduct => {
  const { id, uri, productSpecId, quantity } = gameProductPo;

  return {
    id,
    imgFile: uri ? { uid: uri, url: transformUri(uri), name: uri } : undefined,
    productSpecId: productSpecId,
    quantity,
  };
};

export const transform2GameProductPo = (
  gameProduct: LimitedGameProduct,
  gameDefineId?: Key
): GameProductPo => {
  const {
    id,
    imgFile,
    productSpecId,
    grade,
    count,
    rewardPoints,
    recoverPrice,
  } = gameProduct;

  const gameCategoryList = grade ? [transform2CategoryPO(grade)] : [];

  return {
    id,
    productSpecId,
    gameDefineId,
    count,
    award: rewardPoints,
    gameCategoryList,
    documentId: imgFile?.uid,
    uri: imgFile?.url,
    recoverPrice: transformServerAmount(recoverPrice || 0),
  };
};

export const transform2UnlimitedDrawDefineProduct = (
  gameProduct: InfinityGameProduct,
  gameDefineId?: Key
): UnlimitedDrawDefineProduct => {
  const {
    id,
    imgFile,
    productSpecId,
    grade,
    probability,
    rewardPoints,
    mineDisasterProbability,
    mineDisasterAdvancedProbability,
    shovelFlag,
    clearanceFlag,
    unconventionalitysFlag,
  } = gameProduct;

  const gameCategoryList = grade ? [transform2CategoryPO(grade)] : [];

  return {
    clearanceFlag: clearanceFlag || unconventionalitysFlag === 1 ? 1 : 0,
    shovelFlag: shovelFlag || unconventionalitysFlag === 4 ? 1 : 0,
    backwardFirstFlag: unconventionalitysFlag === 3 ? 1 : 0,
    backwardFlag: unconventionalitysFlag === 2 ? 1 : 0,
    id,
    productSpecId,
    gameDefineId,
    categoryId: grade?.id,
    award: rewardPoints,
    gameCategoryList,
    documentId: imgFile?.uid,
    uri: imgFile?.url,
    probability: isNumber(probability)
      ? transform2ServerProbability(probability)
      : undefined,
    mineDisasterProbability: isNumber(mineDisasterProbability)
      ? transform2ServerProbability(mineDisasterProbability)
      : undefined,
    mineDisasterAdvancedProbability: isNumber(mineDisasterAdvancedProbability)
      ? transform2ServerProbability(mineDisasterAdvancedProbability)
      : undefined,
  };
};

export const transformGameDefinePo = (gameDefinePo: GameDefinePo) => {
  const {
    id,
    name,
    type: poType,
    ticketPrice,
    documentId,
    uri,
    gameProductList = [],
    count,
    canUpdate,
    hiddenPoolCondition,
    hiddenPoolId,
    awardIdList,
    numberPrizes,
    hiddenPoolUri,
    bigPrizeCount,
    smallPrizeCount,
    richManGameDtlDTOList,
    richManCirclePrizes,
    exchangeCodeNum,
    state,
    abandonState,
    hiddenPoolName,
    gameDefineHiddenRelPoList,
    winnerNum,
    winnerCategorys,
    limitType,
    unifiedPassword,
  } = gameDefinePo || {};

  const [type] = gameTypesOptions.filter((item) => item.id === poType);

  return {
    unifiedPassword,
    limitType: limitType && limitType === 2 ? true : false,
    id,
    name,
    type,
    count,
    canUpdate: isBoolean(canUpdate) ? canUpdate : true,
    imgFile: uri
      ? {
          uid: documentId || uri,
          url: transformUri(uri),
          name: documentId || uri,
        }
      : undefined,
    ticketPrice: transformServerAmount(ticketPrice || 0),
    products: gameProductList.map((gameProductPo) =>
      transformGameProductPo(gameProductPo)
    ),
    hiddenPoolCondition,
    awardIdList,
    numberPrizes,
    bigPrizeCount,
    smallPrizeCount,
    exchangeCodeNum,
    state,
    mainProducts: gameDefineHiddenRelPoList
      ? gameDefineHiddenRelPoList.map((item) =>
          transformHiddenDefineProduct(item)
        )
      : hiddenPoolId
      ? [
          {
            productSpecId: hiddenPoolId,
            imgFile: {
              uid: '',
              url: transformUri(hiddenPoolUri || ''),
              name: '',
            },
            name: hiddenPoolName,
          },
        ]
      : undefined,
    lattices: richManGameDtlDTOList?.map((itme) => {
      const { stepNum, type, productSpecId, integral, uri, name } = itme;
      return {
        step: stepNum,
        latticeType: type,
        integral: integral ? integral : undefined,
        welfareProducts: productSpecId
          ? [
              {
                name,
                productSpecId: productSpecId,
                imgFile: { uid: '', url: transformUri(uri || ''), name: '' },
              },
            ]
          : undefined,
      };
    }),
    richManCirclePrizes: (richManCirclePrizes || []).map((gameProductPo) =>
      transformGameProductPo(gameProductPo)
    ),
    isAbandon: abandonState === '2' ? true : false, // 1未废弃  2废弃
    abandonState: abandonState === '2' ? '废弃' : '未废弃', // 1未废弃  2废弃
    winnerNum,
    winnerCategorys,
  };
};

export const transformGameDefinePointPo = (gameDefinePo: GameDefinePo) => {
  const {
    id,
    name,
    type: poType,
    ticketPrice,
    documentId,
    uri,
    gameProductList = [],
    count,
    canUpdate,
    hiddenPoolCondition,
    hiddenPoolId,
    awardIdList,
    numberPrizes,
    hiddenPoolUri,
    bigPrizeCount,
    smallPrizeCount,
    hiddenPoolName,
  } = gameDefinePo || {};
  const [type] = integralRewardTypesOptions.filter(
    (item) => item.id === poType
  );

  return {
    id,
    name,
    type,
    count,
    canUpdate: isBoolean(canUpdate) ? canUpdate : true,
    imgFile: uri
      ? {
          uid: documentId || uri,
          url: transformUri(uri),
          name: documentId || uri,
        }
      : undefined,
    ticketPrice: transformServerAmount(ticketPrice || 0),
    products: gameProductList.map((gameProductPo) =>
      transformGameProductPo(gameProductPo)
    ),
    hiddenPoolCondition,
    awardIdList,
    numberPrizes,
    bigPrizeCount,
    smallPrizeCount,
    mainProducts: hiddenPoolId
      ? [
          {
            name: hiddenPoolName,
            productSpecId: hiddenPoolId,
            imgFile: {
              uid: '',
              url: transformUri(hiddenPoolUri || ''),
              name: '',
            },
          },
        ]
      : undefined,
  };
};

export const transformGameDefineMealtickePo = (gameDefinePo: GameDefinePo) => {
  const {
    id,
    name,
    type: poType,
    ticketPrice,
    documentId,
    uri,
    gameProductList = [],
    count,
    canUpdate,
    hiddenPoolCondition,
    hiddenPoolId,
    awardIdList,
    numberPrizes,
    hiddenPoolUri,
    bigPrizeCount,
    smallPrizeCount,
    hiddenPoolName,
  } = gameDefinePo || {};

  const [type] = mealticketGameTypesOptions.filter(
    (item) => item.id === poType
  );

  return {
    id,
    name,
    type,
    count,
    canUpdate: isBoolean(canUpdate) ? canUpdate : true,
    imgFile: uri
      ? {
          uid: documentId || uri,
          url: transformUri(uri),
          name: documentId || uri,
        }
      : undefined,
    ticketPrice: transformServerAmount(ticketPrice || 0),
    products: gameProductList.map((gameProductPo) =>
      transformGameProductPo(gameProductPo)
    ),
    hiddenPoolCondition,
    awardIdList,
    numberPrizes,
    bigPrizeCount,
    smallPrizeCount,
    mainProducts: hiddenPoolId
      ? [
          {
            name: hiddenPoolName,
            productSpecId: hiddenPoolId,
            imgFile: {
              uid: '',
              url: transformUri(hiddenPoolUri || ''),
              name: '',
            },
          },
        ]
      : undefined,
  };
};

export const transformAddUnlimitedDrawDefineDTO = (
  gameDefinePo: AddUnlimitedDrawDefineDTO
): InfinityGameDefine => {
  const {
    id,
    name,
    type: poType,
    ticketPrice,
    documentId,
    uri,
    productList = [],
    state,
    award,
    roundNum,
    carnivalMode,
    carnivalModeNum,
    carnivalModeMultiplier,
    mineDisasterNum,
    mineDisasterDuration,
    lotteryProportion,
    grandPrizeNum,
    hiddenPoolCondition,
    hiddenPoolId,
    awardIdList,
    numberPrizes,
    hiddenPoolUri,
    batterDraw,
    limitedNum,
    level,
    lotteryAmount,
    abandonState,
    hiddenPoolName,
    gameDefineHiddenRelPoList,
    scoopPattern,
    gameId,
    rageModel,
    rageValue,
  } = gameDefinePo || {};
  const [type] = infinityGameTypesOptions.filter((item) => item.id === poType);
  const pagoda = [
    { id: '17', name: '塔层无限抽(常规模式)' },
    { id: '18', name: '塔模版(常规模式)' },
    { id: '19', name: '塔层无限抽(非常规模式)' },
    { id: '20', name: '塔模版(非常规模式)' },
  ];
  return {
    gameId,
    scoopPattern: scoopPattern === '1' ? true : false,
    id,
    name,
    type: type ? type : pagoda.find((item) => item.id === poType),
    imgFile: uri
      ? {
          uid: documentId || uri,
          url: transformUri(uri),
          name: documentId || uri,
        }
      : undefined,
    ticketPrice: transformServerAmount(ticketPrice || 0),
    products: productList.map((gameProductPo) =>
      transformUnlimitedDrawDefineProduct(gameProductPo)
    ),
    state,
    roundNum,
    carnivalMode,
    carnivalModeNum,
    carnivalModeMultiplier,
    rewardPoints: award,
    mineDisasterNum,
    mineDisasterDuration,
    grandPrizeNum,
    lotteryProportion: isNumber(lotteryProportion)
      ? transformServerProbability(lotteryProportion)
      : undefined,
    hiddenPoolCondition,
    awardIdList,
    numberPrizes,
    batterDraw: batterDraw === 1 ? true : false,
    limitedNum,
    mainProducts: gameDefineHiddenRelPoList
      ? gameDefineHiddenRelPoList.map((item) =>
          transformHiddenDefineProduct(item)
        )
      : hiddenPoolId
      ? [
          {
            name: hiddenPoolName,
            productSpecId: hiddenPoolId,
            imgFile: {
              uid: '',
              url: transformUri(hiddenPoolUri || ''),
              name: '',
            },
          },
        ]
      : undefined,
    level,
    lotteryAmount: lotteryAmount
      ? transformServerAmount(lotteryAmount)
      : undefined,
    isAbandon: abandonState === '2' ? true : false, // 1未废弃  2废弃
    abandonState: abandonState === '2' ? '废弃' : '未废弃', // 1未废弃  2废弃
    rageModel: rageModel ? true : false,
    rageValue,
  };
};
export const transformHiddenDefineProduct = (
  gameProductPo: HiddenDefineProduct
): LimitedGameProduct => {
  const { id, hiddenName, hiddenPoolId, uri, documentId, probability } =
    gameProductPo;
  return {
    id,
    productSpecId: hiddenPoolId,
    name: hiddenName,
    imgFile: { uid: '', url: transformUri(uri || ''), name: '' },
    probability: isNumber(probability)
      ? transformServerProbability(probability)
      : undefined,
  };
};
export const transform2GameDefinePo = (
  gameDefine: LimitedGameDefine
): GameDefinePo => {
  const {
    id,
    name,
    imgFile,
    type,
    ticketPrice,
    products = [],
    awardIdList,
    hiddenPoolCondition,
    mainProducts = [],
    numberPrizes,
    lattices,
    richManCirclePrizes,
    exchangeCodeNum,
    earningRate,
    winnerNum,
    winnerCategorys,
    limitType,
    unifiedPassword,
  } = gameDefine;
  const gameProductList = products?.map((item) =>
    transform2GameProductPo(item, id)
  );
  const richManGameDtlDTOList = lattices?.map((item, index) => {
    const { latticeType, step, welfareProducts, integral } = item;
    return {
      serialNum: index,
      type: latticeType,
      productSpecId: welfareProducts?.[0]?.productSpecId,
      stepNum: step,
      integral,
    };
  });
  const richManCirclePrizeProductList = richManCirclePrizes?.map((item) => {
    return { id: item.productSpecId || item.id };
  });
  const gameDefineHiddenRelPoList = mainProducts?.map((item) =>
    transform2Product(item, id)
  );
  return {
    unifiedPassword,
    limitType: limitType && limitType !== undefined ? 2 : 1,
    gameDefineHiddenRelPoList,
    id,
    name,
    documentId: imgFile?.uid,
    uri: imgFile?.url,
    ticketPrice: transform2ServerAmount(ticketPrice || 0),
    type: type?.id,
    gameProductList,
    awardIdList,
    hiddenPoolCondition,
    hiddenPoolId: mainProducts.length
      ? mainProducts[0].productSpecId
      : undefined,
    numberPrizes,
    richManGameDtlDTOList,
    richManCirclePrizes: richManCirclePrizeProductList,
    exchangeCodeNum,
    earningRate,
    winnerNum,
    winnerCategorys: winnerCategorys?.length ? winnerCategorys.toString() : '',
  };
};

export const transform2AddUnlimitedDrawDefineDTO = (
  gameDefine: InfinityGameDefine
): AddUnlimitedDrawDefineDTO => {
  const {
    id,
    name,
    imgFile,
    type,
    ticketPrice,
    products = [],
    rewardPoints,
    mineDisasterNum,
    mineDisasterDuration,
    lotteryProportion,
    grandPrizeNum,
    awardIdList,
    hiddenPoolCondition,
    mainProducts = [],
    numberPrizes,
    batterDraw,
    limitedNum,
    roundNum,
    carnivalMode,
    carnivalModeNum,
    carnivalModeMultiplier,
    rageModel,
    rageValue,
  } = gameDefine;

  const productList = products?.map((item) =>
    transform2UnlimitedDrawDefineProduct(item, id)
  );
  productList.map((item) => {
    if (item.categoryId === '131') item.probability = 0;
    return item;
  });
  const gameDefineHiddenRelPoList = mainProducts?.map((item) =>
    transform2Product(item, id)
  );
  return {
    gameDefineHiddenRelPoList,
    id,
    name,
    documentId: imgFile?.uid,
    uri: imgFile?.url,
    ticketPrice: transform2ServerAmount(ticketPrice || 0),
    type: type?.id,
    productList,
    award: rewardPoints,
    mineDisasterNum,
    lotteryProportion: isNumber(lotteryProportion)
      ? transform2ServerProbability(lotteryProportion)
      : undefined,
    mineDisasterDuration,
    grandPrizeNum,
    awardIdList,
    hiddenPoolCondition,
    hiddenPoolId: mainProducts.length
      ? mainProducts[0].productSpecId
      : undefined,
    hiddenPoolUri: mainProducts.length
      ? mainProducts[0].imgFile?.url
      : undefined,
    numberPrizes,
    batterDraw: batterDraw ? 1 : 0,
    limitedNum,
    roundNum,
    carnivalMode,
    carnivalModeNum,
    carnivalModeMultiplier,
    rageModel: rageModel ? 1 : 0,
    rageValue,
  };
};

export const transform2Product = (
  gameProduct: LimitedGameProduct,
  gameDefineId?: Key
): HiddenDefineProduct => {
  const { id, imgFile, productSpecId, probability, name } = gameProduct;
  return {
    // id,
    gameDefineId,
    documentId: imgFile?.uid,
    uri: imgFile?.url,
    probability: isNumber(probability)
      ? transform2ServerProbability(probability)
      : undefined,
    hiddenName: name,
    hiddenPoolId: productSpecId,
  };
};

export const transformGamePo = (gamePo: GamePo): Game => {
  const { gameDefineId, state, publishTime, integral, level, lotteryAmount } =
    gamePo;

  const gameDefine = transformGameDefinePo(gamePo);

  return {
    ...gameDefine,
    defineId: gameDefineId,
    state,
    publishTime,
    integral,
    level,
    lotteryAmount: lotteryAmount
      ? transformServerAmount(lotteryAmount)
      : undefined,
  };
};

export const transformGamePointPo = (gamePo: GamePo): Game => {
  const { gameDefineId, state, publishTime, integral, level, lotteryAmount } =
    gamePo;

  const gameDefine = transformGameDefinePointPo(gamePo);

  return {
    ...gameDefine,
    defineId: gameDefineId,
    state,
    publishTime,
    integral,
    level,
    lotteryAmount: lotteryAmount
      ? transformServerAmount(lotteryAmount)
      : undefined,
  };
};

export const transformGameMealtickePo = (gamePo: GamePo): Game => {
  const { gameDefineId, state, publishTime, integral, level, lotteryAmount } =
    gamePo;

  const gameDefine = transformGameDefineMealtickePo(gamePo);

  return {
    ...gameDefine,
    defineId: gameDefineId,
    state,
    publishTime,
    integral,
    level,
    lotteryAmount: lotteryAmount
      ? transformServerAmount(lotteryAmount)
      : undefined,
  };
};

export const transformGamePo2GameBox = (gamePo: GamePo): GameBox => {
  const { totalCount } = gamePo;
  return {
    ...transformGamePo(gamePo),
    totalCount,
  };
};
export const transformGamePo2GamePoint = (gamePo: GamePo): GameBox => {
  const { totalCount } = gamePo;
  return {
    ...transformGamePointPo(gamePo),
    totalCount,
  };
};

export const transformGamePo2GameMealticke = (gamePo: GamePo): GameBox => {
  const { totalCount } = gamePo;
  return {
    ...transformGameMealtickePo(gamePo),
    totalCount,
  };
};

export const transform2GamePo = (game: Game) => {
  const {
    defineId: gameDefineId,
    state,
    publishTime,
    count,
    integral,
    level,
    shelvesCount,
    lotteryAmount,
  } = game;
  const gameDefinePo = transform2GameDefinePo(game);
  return {
    ...gameDefinePo,
    gameDefineId,
    state,
    publishTime,
    modelNum: count,
    integral,
    level,
    shelvesCount,
    lotteryAmount: lotteryAmount
      ? transform2ServerAmount(lotteryAmount)
      : undefined,
  };
};

export const transformUserDto = (dto: UserDTO): VipMember => {
  const {
    userId,
    walletId,
    accountNo,
    balance,
    lockedAmount,
    nickName,
    uri,
    wechatId,
    integration,
    experience,
    wechatName,
    blackFlag,
    mealTicket,
    commentName,
    tokens,
    addedOrderBlackFlag,
    actionBlackFlag,
  } = dto;
  return {
    commentName,
    avatar: uri,
    userId,
    walletId,
    accountNo,
    points: integration,
    balance: transformServerAmount(balance),
    balanceLocked: transformServerAmount(lockedAmount),
    mealTicket: transformServerAmount(mealTicket),
    tokens: transformServerAmount(tokens),
    experience,
    wechatId,
    nickName,
    blackString: blackFlag ? '是' : '否',
    addedOrderBlackFlag,
    blackFlag,
    wechatNickName: wechatName,
    actionBlackFlag,
  };
};

export const transformApplyForDeliveryPo = (
  po: ApplyForDeliveryPo
): DeliveryOrder => {
  const {
    id,
    knapsackId,
    particularAddress,
    deliveryBatchNo,
    applyNo,
    mobile,
    deliveryTime,
    applicationTime,
    productName,
    amount,
    name,
    accountNo,
    uri,
    userUri,
    customerRemark,
    verifyStateDesc,
    auditRemark,
    count,
    discountTypeDesc,
    pendingState,
    pendingStateDesc,
    flagColor,
    flagDesc,
    returnBy,
    returnReason,
    returnTime,
    costPrice,
  } = po;
  return {
    auditRemark,
    verifyStateDesc,
    applyNo,
    knapsackId,
    deliveryId: id,
    accountNo,
    applyTime: applicationTime,
    productName,
    recoverPrice: transformServerAmount(amount),
    imageUrl: transformUri(uri),
    addressDetails: particularAddress,
    mobileNo: mobile,
    shipperName: name,
    deliveryTime,
    shipperAvatar: userUri || '',
    customerRemark,
    count,
    pendingState,
    discountTypeDesc,
    pendingStateDesc,
    flagState: !!flagColor ? '标记' : '未标记',
    flagDesc,
    flagColor,
    returnBy,
    returnReason,
    returnTime,
    costPrice: transformServerAmount(costPrice || 0),
  };
};

export const transformProductTransferRecordsDTO = (
  dto: ProductTransferRecordsDTO
): TransferRecord => {
  const {
    id,
    accountId,
    targetAccountId,
    productSpecId,
    productImageUrl,
    productName,
    productPrice,
    recoverPrice,
    nickName,
    accountUrl,
    targetNickName,
    targetAccountUrl,
    operatorName,
    createdTime,
    costPrice,
  } = dto;
  return {
    costPrice: transformServerAmount(costPrice || 0),
    id,
    accountNo: accountId,
    accountImage: accountUrl,
    nickName,
    targetAccountNo: targetAccountId,
    targetAccountImage: targetAccountUrl,
    targetNickName,
    productSpecId,
    productName,
    productImage: transformUri(productImageUrl),
    price: transformServerAmount(productPrice),
    recoverPrice: transformServerAmount(recoverPrice),
    operatorName,
    operatedTime: createdTime,
  };
};

export const transfromGameRecordsPo = (po: GameRecordsPo): GameRecord => {
  const {
    id,
    accountNo,
    gameId,
    batchNo,
    name,
    count,
    ticketPrice,
    totalPrice,
    gameImageUrl,
    accountImageUrl,
    playTime,
  } = po;
  return {
    imageUrl: transformUri(gameImageUrl),
    batchNo,
    accountNo,
    gameId,
    gameName: name,
    price: transformServerAmount(ticketPrice),
    totalAmount: transformServerAmount(totalPrice),
    gameCount: count,
    userAvatar: accountImageUrl,
    playTime,
  };
};

export const transformOrderPo = (po: OrderPo): TopupRecord => {
  const {
    id,
    account,
    amount,
    investType,
    investBankType,
    investState,
    wechatInvestId,
    describe,
    updatedTime,
    createdBy,
  } = po;

  return {
    createdBy,
    accountNo: account,
    amount: transformServerAmount(amount),
    topupSource: TOPUP_SOURCE_DISPLAY_MAPPING[investType], // 充值来源
    channel: investBankType as string, // 支付渠道
    paymentState: TOPUP_PAYMENT_STATE_DISPLAY_MAPPING[investState], // 支付状态
    wechatOrderNo: wechatInvestId, // 微信订单号
    desc: describe,
    topupTime: updatedTime,
  };
};

export const transformWithdrawRecordsPo = (
  po: WithdrawRecordsPo
): WithdrawRecord => {
  const { id, walletId, amount, openId, state, auditTime, withdrawTime } = po;
  return {
    walletId,
    amount: transformServerAmount(amount),
    wechatId: openId,
    auditState: WITHDRAW_STATE_DISPLAY_MAPPING[state],
    auditTime,
    withdrawTime,
  };
};

export const transformAmountRecordsPo = (po: AmountRecordsPo): BillsRecord => {
  const {
    id,
    account,
    totalAmount,
    amount,
    describe,
    businessKey,
    type,
    businessKeyType,
    recordsTime,
  } = po;
  return {
    accountNo: account,
    amount: transformServerAmount(amount),
    totalAmount: transformServerAmount(totalAmount),
    desc: describe,
    businessKey,
    businessType: BUSINESS_TYPE_DISPLAY_MAPPING[type],
    recordType: RECORD_TYPE_DISPLAY_MAPPING[businessKeyType],
    payTime: recordsTime,
  };
};

export const transformVipLevelCollocationPo = (
  po: VipLevelCollocationPo
): AwardsSetting => {
  const { id, vipKey, vipName, vipLevel } = po;
  return {
    id,
    name: vipName,
    level: vipLevel,
    key: vipKey,
  };
};

export const transformKnapsackPo = (po: KnapsackPo): GarageKit => {
  const {
    id,
    accountNo,
    productSpecId,
    productId,
    name,
    knapsackLock,
    state,
    acquireTime,
    uri,
  } = po;
  return {
    id,
    name,
    accountNo,
    productSpecId,
    lock: knapsackLock === GARAGE_KIT_LOCK ? true : false,
    state,
    acquireTime,
    image: transformUri(uri),
  };
};

export const transformPosterDocumentPo = (
  po: PosterDocumentPo
): UploadFile<DocumentPo> => {
  const { id, documentId, posterListId, orderNumber, uri } = po;
  return {
    uid: id as string,
    name: documentId as string,
    url: transformUri(uri || ''),
  };
};

export const transformPosterListPo = (po: PosterListPo): Poster => {
  const { id, name, description, posterDocumentPos, type, platformType } = po;
  return {
    id,
    title: name,
    desc: description,
    type,
    platformType,
    imgFiles: (posterDocumentPos || []).map((item) =>
      transformPosterDocumentPo(item)
    ),
  };
};

export const transformDailyStatisticsItem = (item: DailyStatisticsItem) => {
  const {
    accountId: accountNo,
    nickName,
    statisticalDate: date,
    remainingAmount: balance,
    bpRecoverAmount,
    bpProductAmount,
    bpToBeShippedAmount,
    bpTotalToBeShippedAmount,
    bpShippedAmount,
    rewardAmount,
    rewardRecoverAmount,
    rewardProductAmount,
    rechargeAmount: topupAmount,
    giftAmount,
    adminRechargeAmount: adminTopupAmount,
    withdrawAmount,
    transferAmount,
    integral: points,
    giftProductTotalAmount,
    bpTotalRecoverAmount,
    bpTotalProductAmount,
  } = item;
  return {
    accountNo,
    nickName,
    date,
    balance: transformServerAmount(balance || 0),
    bpRecoverAmount: transformServerAmount(bpRecoverAmount || 0),
    bpProductAmount: transformServerAmount(bpProductAmount || 0),
    bpToBeShippedAmount: transformServerAmount(bpToBeShippedAmount || 0),
    bpTotalToBeShippedAmount: transformServerAmount(
      bpTotalToBeShippedAmount || 0
    ),
    bpShippedAmount: transformServerAmount(bpShippedAmount || 0),
    rewardAmount: transformServerAmount(rewardAmount || 0),
    rewardRecoverAmount: transformServerAmount(rewardRecoverAmount || 0),
    rewardProductAmount: transformServerAmount(rewardProductAmount || 0),
    topupAmount: transformServerAmount(topupAmount || 0),
    giftAmount: transformServerAmount(giftAmount || 0),
    adminTopupAmount: transformServerAmount(adminTopupAmount || 0),
    withdrawAmount: transformServerAmount(withdrawAmount || 0),
    transferAmount: transformServerAmount(transferAmount || 0),
    points,
    giftProductTotalAmount: transformServerAmount(giftProductTotalAmount || 0),
    bpTotalRecoverAmount: transformServerAmount(bpTotalRecoverAmount || 0),
    bpTotalProductAmount:transformServerAmount(bpTotalProductAmount || 0),
  };
};

export const transformKnapsackAllStatisticsDTO = (
  dto: KnapsackAllStatisticsDTO
): KnapsackStatistic => {
  const { id, num, productPicturePath, productName, stateDesc } = dto;
  return {
    id,
    image: transformUri(productPicturePath),
    name: productName,
    stateDesc,
    count: num,
  };
};
export const transformKnapsackAllDailyStatisticsDTO = (
  dto: KnapsackAllDailyStatisticsDTO
): DailyKnapsackStatistic => {
  const { statisticalDate } = dto;
  return {
    ...transformKnapsackAllStatisticsDTO(dto),
    date: statisticalDate,
  };
};

export const transformKnapsackDailyStatisticsByAccountDTO = (
  dto: KnapsackDailyStatisticsByAccountDTO
): UserKnapsackStatistic => {
  const { accountId, nickName, amount, totalAmount, accountUrl } = dto;
  return {
    ...transformKnapsackAllDailyStatisticsDTO(dto),
    accountNo: accountId,
    nickName,
    price: transformServerAmount(amount),
    totalAmount: transformServerAmount(totalAmount),
    accountImage: accountUrl,
  };
};

export const transformUserRealTimeAmountDTO = (
  dto: UserRealTimeAmountInterfaceDTO
): UserRealTimeAmountInterface => {
  const {
    nickName,
    accountId,
    accountUrl,
    balance,
    knapsackAmount,
    toBeShippedAmount,
    shippedAmount,
    wxRechargeAmount,
    adminRechargeAmount,
    giftAmount,
    withdrawAmount,
    wxRechargeAmountWithinSevenDays,
    shippedAmountWithinSevenDays,
    adminRechargeAmountWithinSevenDays,
    withdrawAmountWithinSevenDays,
    applyForDeliveryWithinSevenDays,
    pendingAmount,
    eventGiftAmount,
    shovelNum,
    profitLossAmount,
    addedLowAmount,
    addedHighBuyAmount,
    integral,
    mealTicket,
    createdTime,
    transferTime,
    luckyBagNum,
    level, //等级
    experiencePer, //经验百分比
    itemsToBeReviewedAmount, //待审核购买物品金额
    pendingDiscountAmount,
    giftMealTicket,
    tokens,
    rageNum,
    releaseAmount,
    releaseCostAmount,
    strikeAmount,
    strikeCostAmount,
    historyRechargeAmount,
    historyAdminRechargeAmount,
    accountColor,
    falseChargeProfitLoss,
    addedProfitLoss,
    biddingAmount
  } = dto;
  return {
    biddingAmount:transformServerAmount(biddingAmount || 0),
    falseChargeProfitLoss:transformServerAmount(falseChargeProfitLoss||0),
    addedProfitLoss:transformServerAmount(addedProfitLoss||0),
    accountColor,
    historyRechargeAmount:transformServerAmount(historyRechargeAmount || 0),
    historyAdminRechargeAmount:transformServerAmount(historyAdminRechargeAmount || 0),
    releaseCostAmount:transformServerAmount(releaseCostAmount||0),
    strikeAmount:transformServerAmount(strikeAmount||0),
    strikeCostAmount:transformServerAmount(strikeCostAmount||0),
    releaseAmount:transformServerAmount(releaseAmount||0),
    giftMealTicket: transformServerAmount(giftMealTicket || 0),
    pendingDiscountAmount: transformServerAmount(pendingDiscountAmount || 0),
    luckyBagNum,
    createdTime,
    nickName,
    accountId,
    level,
    experiencePer,
    itemsToBeReviewedAmount: transformServerAmount(itemsToBeReviewedAmount),
    balance: transformServerAmount(balance),
    knapsackAmount: transformServerAmount(knapsackAmount),
    shippedAmount: transformServerAmount(shippedAmount),
    toBeShippedAmount: transformServerAmount(toBeShippedAmount),
    wxRechargeAmount: transformServerAmount(wxRechargeAmount),
    adminRechargeAmount: transformServerAmount(adminRechargeAmount),
    giftAmount: transformServerAmount(giftAmount),
    withdrawAmount: transformServerAmount(withdrawAmount),
    accountUrl: accountUrl,
    wxRechargeAmountWithinSevenDays: transformServerAmount(
      wxRechargeAmountWithinSevenDays
    ),
    shippedAmountWithinSevenDays: transformServerAmount(
      shippedAmountWithinSevenDays
    ),
    adminRechargeAmountWithinSevenDays: transformServerAmount(
      adminRechargeAmountWithinSevenDays
    ),
    withdrawAmountWithinSevenDays: transformServerAmount(
      withdrawAmountWithinSevenDays
    ),
    applyForDeliveryWithinSevenDays: transformServerAmount(
      applyForDeliveryWithinSevenDays
    ),
    pendingAmount: transformServerAmount(pendingAmount),
    eventGiftAmount: transformServerAmount(eventGiftAmount),
    shovelNum,
    profitLossAmount: transformServerAmount(profitLossAmount),
    addedLowAmount: transformServerAmount(addedLowAmount),
    addedHighBuyAmount: transformServerAmount(addedHighBuyAmount),
    integral,
    mealTicket: transformServerAmount(mealTicket),
    transferTime: !!transferTime ? formatDatetime(transferTime) : '-',
    tokens: transformServerAmount(tokens || 0),
    rageNum,
  };
};

export const transformGiveGiftsRecordPo = (
  po: GiveGiftsRecordPo
): GiveGiftRecord => {
  const {
    id,
    accountId,
    productImageUrl,
    productName,
    productPrice,
    recoverPrice,
    accountImageUrl,
    createdTime,
    description,
    typeDesc,
    state,
    nickName,
  } = po;
  return {
    operator: nickName,
    id,
    accountNo: accountId,
    avatar: accountImageUrl,
    productName,
    productImage: transformUri(productImageUrl),
    giveTime: createdTime,
    price: transformServerAmount(productPrice),
    recoverPrice: transformServerAmount(recoverPrice),
    description: description
      ? description.split('：')[1]
        ? description.split('：')[1]
        : description
      : '',
    typeDesc,
    state,
  };
};

export const transformChefListFileListPo = (
  po: ChefListFileListPo
): KitchenGod => {
  const { id, fileName, uploadTime, documentUrl } = po;
  return {
    id,
    fileName: fileName.split('.')[0],
    uploadTime,
    documentUrl,
  };
};

export const transformChefListFileListDto = (
  po: ChefListFileListDto
): KitchenGodFileList => {
  const { accountNo, ranking, nickName } = po;
  return {
    accountNo,
    ranking,
    nickName,
  };
};

export const transformSaveLeaderboardConfigPo = (
  gameDefinePo: SaveRankingsPrizePoolDTO
): RankingPoster => {
  const {
    id,
    leaderboardName,
    leaderboardType,
    leaderboardTypeDesc,
    defaultIconUrl,
    defaultIconDocumentId,
    terminalType,
    terminalTypeDesc,
    state,
    stateDesc,
    productList,
    chefListFileId,
    bigPrizeId,
    leaderboardStageRewardDTOList,
  } = gameDefinePo || {};

  return {
    leaderboardStageRewardDTOList: leaderboardStageRewardDTOList?.map((item) =>
      transformleaderboardStageRewardDTOList(item)
    ),
    id,
    leaderboardName,
    leaderboardType,
    leaderboardTypeDesc,
    defaultIcon: defaultIconUrl
      ? {
          uid: defaultIconDocumentId || defaultIconUrl,
          url: transformUri(defaultIconUrl),
          name: defaultIconDocumentId || defaultIconUrl,
        }
      : undefined,
    defaultIconUrl: transformUri(defaultIconUrl || ''),
    terminalType,
    terminalTypeDesc,
    state,
    stateDesc,
    chefListFileId,
    productList: productList?.map((gameProductPo) =>
      transformRankingListManageProduct(gameProductPo)
    ),
    bigPrizeId,
  };
};
export const transformleaderboardStageRewardDTOList = (
  po: leaderboardStageRewardDTOList
): leaderboardStageRewardDTOList => {
  const {
    serialNum,
    condition,
    awardType,
    award,
    productRewardPoList,
    firstFew,
    firstFewMultiplier,
    limitedNum,
    multiplier,
  } = po;
  return {
    limitedNum,
    multiplier,
    serialNum,
    condition,
    awardType,
    award: [2, 4, 6].includes(awardType!)
      ? transformServerAmount(award || 0)
      : award,
    productRewardPoList: productRewardPoList?.map((item) =>
      transformRankingListManageProduct(item)
    ),
    firstFew,
    firstFewMultiplier,
  };
};
export const transform2SaveLeaderboardConfigDTO = (
  gameDefine: RankingPoster
): SaveRankingsPrizePoolDTO => {
  const {
    id,
    leaderboardName,
    leaderboardType,
    defaultIcon,
    terminalType,
    state,
    chefListFileId,
    productList = [],
    bigPrizeIdList,
    leaderboardStageRewardDTOList,
  } = gameDefine;

  return {
    leaderboardStageRewardDTOList: leaderboardStageRewardDTOList?.map((item) =>
      transform2leaderboardStageRewardDTOList(item)
    ),
    id,
    leaderboardName,
    leaderboardType,
    defaultIconDocumentId: defaultIcon?.uid,
    terminalType,
    state,
    chefListFileId,
    productSpecIdList: productList?.map((item) => {
      if (item.productSpecId === undefined) {
        return item.id;
      } else {
        return item.productSpecId;
      }
    }),
    bigPrizeIdList,
  };
};
export const transform2leaderboardStageRewardDTOList = (
  po: leaderboardStageRewardDTOList
): leaderboardStageRewardDTOList => {
  const {
    serialNum,
    condition,
    awardType,
    award,
    productRewardPoList,
    firstFew,
    firstFewMultiplier,
    limitedNum,
    multiplier,
  } = po;
  return {
    serialNum,
    condition,
    awardType,
    limitedNum,
    multiplier,
    award:
      awardType && [2, 4, 6].includes(awardType)
        ? transform2ServerAmount(award || 0)
        : award,
    productRewardPoList: productRewardPoList?.map((item) => {
      return {
        productSpecId: item.productSpecId,
        quantity: item.quantity,
      };
    }),
    firstFew,
    firstFewMultiplier,
  };
};
// export const

export const transformGetChefListFileListPo = (
  po: ChefListFileListPo
): RankingList => {
  const { id, fileName } = po;

  return {
    id,
    name: fileName.split('.')[0],
  };
};

export const transformAddOrUpdatePointsDrawDefineDTO = (
  gameDefinePo: AddOrUpdatePointsDrawDefineDTO
): PointsDrawDefine => {
  const {
    id,
    name,
    type: poType,
    documentId,
    uri,
    integral,
    productList = [],
    gameProductList = [],
    canUpdate,
    bigPrizeCount,
    smallPrizeCount,
    abandonState,
    limitType,
  } = gameDefinePo || {};

  const [type] = integralRewardTypesOptions.filter(
    (item) => item.id === poType
  );

  return {
    limitType: limitType && limitType === 2 ? true : false,
    id,
    name,
    type,
    imgFile: uri
      ? {
          uid: documentId || uri,
          url: transformUri(uri),
          name: documentId || uri,
        }
      : undefined,
    integral,
    canUpdate,
    products: productList.map((gameProductPo) =>
      transformPointsDrawDefineProduct(gameProductPo)
    ),
    bigPrizeCount,
    smallPrizeCount,
    gameproducts: gameProductList.map((gameProductPo) =>
      transformPointsDrawDefineProduct(gameProductPo)
    ),
    isAbandon: abandonState === '2' ? true : false, // 1未废弃  2废弃
    abandonState: abandonState === '2' ? '废弃' : '未废弃', // 1未废弃  2废弃
  };
};

export const transform2PointsDrawDefineProduct = (
  gameProduct: PointsDrawProduct,
  gameDefineId?: Key
): PointsDrawDefineProduct => {
  const { id, imgFile, productSpecId, grade, probability, count } = gameProduct;

  const gameCategoryList = grade ? [transform2CategoryPO(grade)] : [];

  return {
    id,
    productSpecId,
    gameDefineId,
    categoryId: grade?.id,
    gameCategoryList,
    documentId: imgFile?.uid,
    uri: imgFile?.url,
    count,
    probability: isNumber(probability)
      ? transform2ServerProbability(probability)
      : undefined,
  };
};

export const transform2AddPointsDrawDefineDTO = (
  gameDefine: PointsDrawDefine
): AddOrUpdatePointsDrawDefineDTO => {
  const {
    id,
    name,
    imgFile,
    type,
    integral,
    products = [],
    gameproducts = [],
    earningRate,
    limitType,
  } = gameDefine;

  const productList = products?.map((item) =>
    transform2PointsDrawDefineProduct(item, id)
  );
  const gameProductList = gameproducts?.map((item) =>
    transform2PointsDrawDefineProduct(item, id)
  );

  return {
    limitType: limitType ? 2 : 1,
    id,
    name,
    integral,
    documentId: imgFile?.uid,
    uri: imgFile?.url,
    type: type?.id,
    productList: type?.id === '09' ? productList : undefined,
    gameProductList:
      type?.id === '07' || type?.id === '08' ? gameProductList : undefined,
    earningRate,
  };
};

export const transformPointsDrawDefineProduct = (
  gameProductPo: PointsDrawDefineProduct
): PointsDrawProduct => {
  const {
    id,
    documentId,
    uri,
    categoryId,
    productSpecId,
    gameDefineId,
    count,
    gameCategoryList = [],
    probability,
    recoverPrice,
    productName,
  } = gameProductPo;

  const [gradeCatgetoryPo] = (gameCategoryList || []).filter(
    (item) => item.id === categoryId
  );

  const grade = transfromCategoryPo(gradeCatgetoryPo);
  return {
    id,
    imgFile:
      documentId && uri
        ? { uid: documentId, url: transformUri(uri), name: documentId }
        : undefined,
    productSpecId: productSpecId,
    grade,
    name: productName,
    probability: isNumber(probability)
      ? transformServerProbability(probability)
      : undefined,
    count,
    recoverPrice: transformServerAmount(recoverPrice || 0),
  };
};
export const transformGiftUserFileListPo = (
  po: GiftUserFileListPo
): GiftManageFile => {
  const { id, fileName, uploadTime, documentUrl, createdBy, stateDesc, state } =
    po;
  return {
    id,
    fileName: fileName.split('.')[0],
    uploadTime,
    documentUrl,
    createdBy,
    stateDesc,
    state,
  };
};

export const transformGiftUserFileListDTO = (
  po: GiftUserFileListDTO
): GiftManageFileList => {
  const {
    accountId,
    productName,
    nickName,
    typeDesc,
    productRecoverPrice,
    describe,
    state,
  } = po;
  return {
    accountId,
    productName,
    nickName,
    typeDesc,
    productRecoverPrice: transformServerAmount(productRecoverPrice || 0),
    describe,
    state,
  };
};

export const transformSaveSignInRulesConfigPo = (
  gameDefinePo: saveSignInRulesConfigDTO
): SignInManagePoster => {
  const {
    id,
    name,
    taskDesc,
    type,
    typeDesc,
    state,
    stateDesc,
    rewardType,
    rewardTypeDesc,
    award,
    ruleType,
    ruleTypeDesc,
    productList,
    rewardProductList,
    cumulativeQuantity,
    condition,
    startDate,
    endDate,
    taskBelong,
    frequency,
    achievementTaskUrl: uri,
    documentId,
  } = gameDefinePo || {};

  return {
    id,
    name,
    taskDesc,
    type,
    typeDesc,
    state,
    stateDesc,
    rewardType,
    rewardTypeDesc,
    award,
    ruleType,
    ruleTypeDesc,
    cumulativeQuantity,
    startDate,
    endDate,
    taskBelong,
    frequency,
    condition: transformServerAmount(condition || 0),
    imgFile: uri
      ? {
          uid: documentId || uri,
          url: transformUri(uri),
          name: documentId || uri,
        }
      : undefined,
    products:
      productList?.length !== 0
        ? productList?.map((gameProductPo: UnlimitedDrawDefineProduct) =>
            transformUnlimitedDrawDefineProduct(gameProductPo)
          )
        : [],
    rewards:
      rewardProductList?.length !== 0
        ? rewardProductList?.map((gameProductPo: UnlimitedDrawDefineProduct) =>
            transformUnlimitedDrawDefineProduct(gameProductPo)
          )
        : [],
  };
};

export const transform2SaveSignInRulesConfigDTO = (
  gameDefine: SignInManagePoster
): saveSignInRulesConfigDTO => {
  const {
    id,
    name,
    taskDesc,
    type,
    typeDesc,
    state,
    stateDesc,
    rewardType,
    rewardTypeDesc,
    award,
    ruleType,
    ruleTypeDesc,
    products,
    cumulativeQuantity,
    startDate,
    endDate,
    rewards,
    condition,
    taskBelong,
    frequency,
    imgFile,
  } = gameDefine;

  const productList = products?.map((item) =>
    transform2SignInDrawDefineProduct(item, id)
  );
  const rewardProductList = rewards?.map((item) =>
    transform2SignInDrawDefineProduct(item, id)
  );

  return {
    id,
    name,
    taskDesc,
    type,
    typeDesc,
    state,
    stateDesc,
    rewardType,
    rewardTypeDesc,
    award,
    ruleType,
    ruleTypeDesc,
    productList,
    cumulativeQuantity,
    rewardProductList,
    taskBelong,
    frequency,
    documentId: imgFile?.uid,
    condition: condition && transform2ServerAmount(condition),
    startDate: startDate && moment(startDate.format('YYYY-MM-DD')).valueOf(),
    endDate:
      endDate &&
      moment(endDate.format('YYYY-MM-DD'))
        .add(1, 'day')
        .subtract(1, 'second')
        .valueOf(),
  };
};

export const transform2SignInDrawDefineProduct = (
  gameProduct: InfinityGameProduct,
  gameDefineId?: Key
): UnlimitedDrawDefineProduct => {
  const { productSpecId, quantity } = gameProduct;

  return {
    productSpecId,
    quantity,
  };
};

export const transformProfitLossDTO = (dto: ProfitLossDTO): ProfitLosss => {
  const {
    name,
    accountNo,
    userName,
    accountImageUrl,
    ticketPrice,
    recoverPrice,
    rebateAmount,
    profitAndLossAmount,
  } = dto;
  return {
    name,
    accountNo,
    userName,
    accountImageUrl,
    ticketPrice: transformServerAmount(ticketPrice),
    recoverPrice: transformServerAmount(recoverPrice),
    rebateAmount: transformServerAmount(rebateAmount),
    profitAndLossAmount: transformServerAmount(profitAndLossAmount),
  };
};

export const transformAuditListPo = (po: AuditListPo): ArticleReviewAudit => {
  const {
    id,
    type,
    state,
    nickName,
    filePath,
    productName,
    amount,
    submitAuditName,
    submitAuditTime,
    auditAccountName,
    auditTime,
    errorMsg,
    describe,
    accountNo,
    mealTicket,
    tokens,
    productPrice,
    recoverPrice,
  } = po;
  return {
    id,
    type,
    state,
    nickName: nickName || '',
    filePath: filePath ? transformUri(filePath) : '',
    productName: productName || '',
    amount: transformServerAmount(amount) || '',
    submitAuditName,
    submitAuditTime: formatDatetime(submitAuditTime),
    auditAccountName,
    auditTime: auditTime ? formatDatetime(auditTime) : '',
    errorMsg: errorMsg || '',
    describe: describe || '',
    accountNo,
    mealTicket: transformServerAmount(mealTicket) || '',
    tokens: transformServerAmount(tokens) || '',
    productPrice: transformServerAmount(productPrice) || '',
    recoverPrice: transformServerAmount(recoverPrice) || '',
  };
};

export const transformUserRecyclingRecordsDTO = (
  dto: UserRecyclingRecordsDTO
): UserRecyclingRecords => {
  const {
    accountNo,
    nickName,
    accountImageUrl,
    productName,
    num,
    returnedAmount,
    returnedTime,
  } = dto;
  return {
    accountNo,
    nickName,
    accountImageUrl,
    productName,
    count: num,
    returnedAmount: transformServerAmount(returnedAmount),
    returnedTime,
  };
};

export const transformSaveSaveItemPoolDTO = (
  gameDefinePo: SaveItemPoolDTO
): MainItemPool => {
  const {
    id,
    name,
    uri,
    price,
    recoverPrice,
    productCategoryList = [],
    productName,
    state,
    showFlag,
    modeType,
    limitType
  } = gameDefinePo || {};

  const categories = productCategoryList
    .filter((po) => po.superCategoryId === PRODUCT_BASE_CATEGORY_ID_CATEGORY)
    .map((po) => {
      return transfromCategoryPo(po);
    });

  const [ip] = productCategoryList
    .filter((po) => po.superCategoryId === PRODUCT_BASE_CATEGORY_ID_IP)
    .map((po) => {
      return transfromCategoryPo(po);
    });

  const [type] = productCategoryList
    .filter((po) => po.superCategoryId === PRODUCT_BASE_CATEGORY_ID_TYPE)
    .map((po) => {
      return transfromCategoryPo(po);
    });

  return {
    modeType:modeType ===1 ? '固定' : '自由',
    limitType:limitType === 1 ? '总量限制' : limitType === 2 ?  '每日限制' : '不限制',
    showFlag:showFlag === 1 ? '全局使用' :'仅后台使用',
    state,
    productName,
    id,
    name,
    imgFile: uri
      ? {
          uid: uri,
          url: transformUri(uri),
          name: uri,
        }
      : undefined,
    categories,
    ip,
    type,
    price: transformServerAmount(price || 0),
    recoverPrice: transformServerAmount(recoverPrice || 0),
  };
};

export const transformSaveItemPoolDTO = (
  gameDefinePo: SaveItemPoolDTO
): ItemPoolsSepc => {
  const { id, name, mainProductSpecId, uri, childProductList, recoverPrice } =
    gameDefinePo || {};

  return {
    id,
    name,

    mainProducts:
      mainProductSpecId && uri
        ? [
            {
              recoverPrice,
              productSpecId: mainProductSpecId,
              imgFile: { uid: '', url: transformUri(uri || ''), name: '' },
            },
          ]
        : undefined,
    products: childProductList?.map((gameProductPo) => {
      const { id, uri, name, productSpecId, probability, recoverPrice } =
        gameProductPo;

      return {
        id,
        name,
        imgFile: { uid: '', url: transformUri(uri || ''), name: '' },
        productSpecId: productSpecId,
        probability: isNumber(probability)
          ? transformServerProbability(probability)
          : undefined,
        recoverPrice: transformServerAmount(recoverPrice || 0),
      };
    }),
  };
};

export const transform2SaveItemPoolDTO = (
  gameDefine: ItemPoolsSepc
): SaveItemPoolDTO => {
  const { id, name, mainProducts = [], products = [], } = gameDefine;

  const childProductList = products?.map((item) =>
    transform2SaveItemPoolProduct(item)
  );

  return {
    id,
    name,
    mainProductSpecId: mainProducts[0].productSpecId || '',
    childProductList,
  };
};

export const transform2SaveItemPoolProduct = (
  gameProduct: ItemPoolProduct
): ItemPoolProductDTO => {
  const { productSpecId, probability } = gameProduct;

  return {
    productSpecId,
    probability: isNumber(probability)
      ? transform2ServerProbability(probability)
      : undefined,
  };
};

export const transformSaveHiddenPoolDTO = (
  gameDefinePo: SaveHiddenPoolDTO
): HiddenPoolGame => {
  const {
    id,
    name,
    documentId,
    uri,
    hiddenPoolProductConfigDTOList = [],
    state,
  } = gameDefinePo || {};

  return {
    id,
    name,
    imgFile: uri
      ? {
          uid: documentId || uri,
          url: transformUri(uri),
          name: documentId || uri,
        }
      : undefined,
    products: hiddenPoolProductConfigDTOList?.map((gameProductPo) => {
      const {
        id,
        uri,
        productSpecId,
        probability,
        name,
        recoverPrice,
        shovelFlag,
      } = gameProductPo;

      return {
        shovelFlag: shovelFlag === 1 ? true : false,
        id,
        name,
        imgFile: { uid: '', url: transformUri(uri || ''), name: '' },
        productSpecId: productSpecId,
        probability: isNumber(probability)
          ? transformServerProbability(probability)
          : undefined,
        recoverPrice: transformServerAmount(recoverPrice || 0),
      };
    }),
    state,
  };
};

export const transform2SaveHiddenPoolDTO = (
  gameDefine: HiddenPoolGame
): SaveHiddenPoolDTO => {
  const { id, name, imgFile, products = [] } = gameDefine;

  const hiddenPoolProductConfigDTOList = products?.map((item) =>
    transform2SavePointsPrizePoolProduct(item, id)
  );

  return {
    id,
    name,
    documentId: imgFile?.uid,
    uri: imgFile?.url,
    hiddenPoolProductConfigDTOList,
  };
};

export const transform2SavePointsPrizePoolProduct = (
  gameProduct: InfinityGameProduct,
  gameDefineId?: Key
): UnlimitedDrawDefineProduct => {
  const { productSpecId, probability, shovelFlag } = gameProduct;

  return {
    shovelFlag: shovelFlag ? 1 : 0,
    productSpecId,
    probability: isNumber(probability)
      ? transform2ServerProbability(probability)
      : undefined,
  };
};

export const transfromPrizeRecordsPo = (po: PrizeRecordsPo): PrizeRecords => {
  const {
    id,
    name,
    gameImageUrl,
    accountNo,
    userName,
    playTime,
    rewardName,
    grade,
    sequence,
    multiplier,
  } = po;
  return {
    multiplier,
    id,
    accountNo,
    name,
    gameImageUrl: transformUri(gameImageUrl),
    userName,
    playTimeStr: playTime ? formatDatetime(playTime) : '',
    rewardName,
    grade,
    sequence,
  };
};

export const transfromDailyOperationTitlePo = (
  po: { [s: string]: unknown } | ArrayLike<unknown>
) => {
  let arr = [];
  for (const [key, value] of Object.entries(po)) {
    arr.push({
      title: value,
      dataIndex: key,
      key,
    });
  }
  return arr;
};

// export const transformAddressPo = (
//   addressPo: AddressPo
// ): AdministrativeDivisionItem => {
//   const {
//     id,
//     parentId,
//     level, // 应该是number类型
//     name,
//   } = addressPo;
//   return {
//     // prefix, // 后端缺
//     name,
//     code: id,
//     value: id,
//     label: name,
//     isLeaf: false,
//     parentId: Number(parentId),
//   };
// };

export const transformUserAddressPo = (
  userAddressPo: UserAddressPo
): Address => {
  const {
    id,
    provinceCode,
    provinceName,
    cityCode,
    cityName,
    districtCode,
    districtName,
    streetCode,
    streetName,
    particularAddress,
    name,
    mobile,
  } = userAddressPo || {};
  return {
    id,
    // consigneeName, // 后端缺
    // mobile, // 后端缺
    province: {
      code: provinceCode,
      name: provinceName,
      isLeaf: false,
    },
    city: {
      code: cityCode,
      name: cityName,
      isLeaf: false,
    },
    district: {
      code: districtCode,
      name: districtName,
      isLeaf: false,
    },
    street: {
      code: streetCode,
      name: streetName,
      isLeaf: false,
    },
    details: particularAddress,
    consigneeName: name,
    mobile,
  };
};

export const transformExChangListDTO = (
  po: ExchangCodeListPo
): VoucherDefine => {
  const {
    id,
    gameDefineId,
    exchangeCode,
    exchangeTimeStr,
    stateDesc,
    accountNo,
    nickName,
    productName,
    state,
    recoverPrice,
    price,
  } = po;
  return {
    id,
    gameDefineId,
    exchangeCode,
    exchangeTimeStr,
    stateDesc,
    accountNo,
    nickName,
    productName,
    state,
    recoverPrice:
      recoverPrice !== null
        ? transformServerAmount(recoverPrice) + '元'
        : recoverPrice,
    price: price !== null ? transformServerAmount(price) + '元' : price,
  };
};

export const transformExGameDefinePo = (gameDefinePo: GameDefinePo) => {
  const {
    id,
    name,
    type: pytype,
    ticketPrice,
    documentId,
    uri,
    gameProductList = [],
    count,
    canUpdate,
    hiddenPoolCondition,
    hiddenPoolId,
    awardIdList,
    numberPrizes,
    hiddenPoolUri,
    bigPrizeCount,
    smallPrizeCount,
    exchangeCodeNum,
    state,
    abandonState,
    unifiedPassword,
  } = gameDefinePo || {};

  const type = {
    id: pytype as Key,
    name: '优惠卷',
  };

  return {
    unifiedPassword,
    abandonState: abandonState === '2' ? '废弃' : '未废弃', // 1未废弃  2废弃
    id,
    name,
    type,
    count,
    canUpdate: isBoolean(canUpdate) ? canUpdate : true,
    imgFile: uri
      ? {
          uid: documentId || uri,
          url: transformUri(uri),
          name: documentId || uri,
        }
      : undefined,
    ticketPrice: transformServerAmount(ticketPrice || 0),
    products: gameProductList.map((gameProductPo) =>
      transformGameProductPo(gameProductPo)
    ),
    hiddenPoolCondition,
    awardIdList,
    numberPrizes,
    bigPrizeCount,
    smallPrizeCount,
    exchangeCodeNum,
    state,
    mainProducts: hiddenPoolId
      ? [
          {
            productSpecId: hiddenPoolId,
            imgFile: {
              uid: '',
              url: transformUri(hiddenPoolUri || ''),
              name: '',
            },
          },
        ]
      : undefined,
  };
};

export const transformBatchListingPo = (gameDefinePo: BatchListingPo) => {
  const {
    id,
    name,
    documentId,
    uri,
    defineCount,
    shelfDate,
    shelfUserName,
    operateTime,
  } = gameDefinePo || {};

  return {
    id,
    name,
    imgFile: uri
      ? {
          uid: documentId || uri,
          url: transformUri(uri),
          name: documentId || uri,
        }
      : undefined,
    defineCount,
    shelfDate: formatDate(shelfDate as number),
    shelfUserName,
    operateTime: formatDatetime(operateTime as number),
  };
};

export const transformSaveBatchListingPo = (
  gameDefinePo: SaveBatchListingPo
) => {
  const { batchShelfTemplateRelDTOList } = gameDefinePo || {};

  return {
    ...transformBatchListingPo(gameDefinePo),
    products: (batchShelfTemplateRelDTOList || []).map((item) => {
      const {
        id,
        templateId,
        gameDefineId,
        shelfTime,
        num,
        gameDefineName,
        gameDefineUri,
        gameDefineType,
        gameDefineTicketPrice,
      } = item;

      const [type] = gameTypesOptions.filter(
        (item) => item.id === gameDefineType
      );

      return {
        id,
        templateId,
        productSpecId: gameDefineId,
        shelfTime,
        count: num,
        imgFile: gameDefineUri
          ? {
              uid: gameDefineId || gameDefineUri,
              url: transformUri(gameDefineUri),
              name: gameDefineId || gameDefineUri,
            }
          : undefined,
        ticketPrice: transformServerAmount(gameDefineTicketPrice || 0),
        type,
        name: gameDefineName,
      };
    }),
  };
};

export const transform2SaveBatchListingPo = (
  gameDefine: SaveBatchListings
): SaveBatchListingPo => {
  const { id, name, imgFile, defineCount, products } = gameDefine;

  return {
    id,
    name,
    uri: imgFile?.url,
    documentId: imgFile?.uid,
    defineCount,
    batchShelfTemplateRelDTOList: (products || []).map((item) => {
      const { productSpecId, shelfTime, count } = item;

      return {
        gameDefineId: productSpecId,
        shelfTime,
        num: count,
      };
    }),
  };
};

export const transformOrderNotYetShippedGoodPo = (
  gameDefinePo: OrderNotYetShippedGoodPo
) => {
  const { id, count, productName, productUri } = gameDefinePo || {};

  return {
    id,
    count,
    productName,
    productUri: transformUri(productUri || ''),
  };
};

export const transfromUserCollectionRecordsPo = (
  po: UserCollectionRecordsPo
): UserCollectionRecords => {
  const {
    id,
    accountNo,
    nickName,
    state,
    receiveTime,
    rewardType,
    award,
    productName,
    productUri,
    businessType,
    desc,
  } = po;
  return {
    productUri: productUri ? transformUri(productUri) : '',
    id,
    accountNo,
    nickName,
    state,
    receiveTime: receiveTime ? formatDatetime(receiveTime) : '',
    rewardType,
    // award: award? transformServerAmount(award) as unknown as string: '',
    amount:
      rewardType === 2
        ? award
          ? (transformServerAmount(award) as unknown as string)
          : ''
        : '',
    integral:
      rewardType === 1 ? (award ? (award as unknown as string) : '') : '',
    mealticket:
      rewardType === 4
        ? award
          ? (transformServerAmount(award) as unknown as string)
          : ''
        : '',
    tokens: rewardType === 5 ? transformServerAmount(award) : '',
    productName,
    businessType,
    desc,
  };
};

export const transformSelectRankingRewardDTO = (
  gameDefine: SelectRankingRewardDTO
): SelectRankingRewars => {
  const {
    id,
    name,
    startEffectiveDate,
    endEffectiveDate,
    leaderboardName,
    state,
  } = gameDefine;

  return {
    id,
    name,
    startEffectiveDate,
    endEffectiveDate,
    leaderboardName,
    state,
  };
};

export const transform2SaveRankingRewardDTO = (
  gameDefine: AddRankingReward
): SaveRankingRewardDTO => {
  const { id, name, lattices } = gameDefine;

  const dtlDTOList = transform2SaveLatticesDTO(lattices);

  return {
    id,
    name,
    dtlDTOList,
  };
};

export const transform2SaveLatticesDTO = (
  gameDefine: RankingRewards[]
): SaveRankingRewards[] => {
  let res: SaveRankingRewards[] = [];

  gameDefine.forEach((item, index) => {
    const {
      modeType,
      rank,
      rewardType,
      award,
      start,
      end,
      products,
      awardMoney,
      meetCondition,
      cappingCondition,
      mealticket,
      tokens,
    } = item;

    if (modeType === MODE_SPECIFIC) {
      res.push({
        ranking: rank,
        rewardType,
        award:
          rewardType === REWARD_INTEGRAL
            ? award
            : rewardType === REWARD_MEALTICKET
            ? transform2ServerAmount(mealticket || 0)
            : rewardType === REWARD_TOKEN
            ? transform2ServerAmount(tokens || 0)
            : transform2ServerAmount(awardMoney || 0),
        productSpecId: (products || [])[0]?.productSpecId,
        index,
        meetCondition,
        cappingCondition,
      });
    } else if (modeType === MODE_CONDITION || modeType === MODE_TOP) {
      res.push({
        rewardType,
        award:
          rewardType === REWARD_INTEGRAL
            ? award
            : rewardType === REWARD_MEALTICKET
            ? transform2ServerAmount(mealticket || 0)
            : rewardType === REWARD_TOKEN
            ? transform2ServerAmount(tokens || 0)
            : transform2ServerAmount(awardMoney || 0),
        productSpecId: (products || [])[0]?.productSpecId,
        index,
        meetCondition,
        cappingCondition,
      });
    } else {
      for (let i = start || 0; i <= (end || 0); i++) {
        res.push({
          ranking: i,
          rewardType,
          award:
            rewardType === REWARD_INTEGRAL
              ? award
              : rewardType === REWARD_MEALTICKET
              ? transform2ServerAmount(mealticket || 0)
              : rewardType === REWARD_TOKEN
              ? transform2ServerAmount(tokens || 0)
              : transform2ServerAmount(awardMoney || 0),
          productSpecId: (products || [])[0]?.productSpecId,
          index,
          meetCondition,
          cappingCondition,
        });
      }
    }
  });

  return res;
};

export const transformSelectRankingRewardIDDTO = (
  gameDefine: SelectRankingRewardIDDTO
): SelectRankingRewarIDwebs => {
  const { id, name, dtlDTOList } = gameDefine;

  const groups = dtlDTOList?.reduce<SaveRankingRewardIDs[][]>(
    (ini, product) => {
      const [targetGroup] = ini.filter((grp) => {
        const [first] = grp;
        return first.index === product.index;
      });
      if (targetGroup) targetGroup.push(product);
      else ini.push([product]);
      return ini;
    },
    []
  );

  const val: RankingRewards[] = groups.map((item) => {
    const [first] = item;
    const {
      ranking,
      award,
      rewardType,
      productSpecId,
      productUri,
      productName,
      meetCondition,
      cappingCondition,
    } = first;

    return item.length > 1
      ? {
          modeType: 2,
          start: ranking,
          end: (ranking || 0) + item.length - 1,
          rewardType,
          award: rewardType === REWARD_INTEGRAL ? award : undefined,
          products: productSpecId
            ? [
                {
                  productSpecId: productSpecId,
                  imgFile: {
                    uid: '',
                    url: transformUri(productUri || ''),
                    name: '',
                  },
                  name: productName,
                },
              ]
            : undefined,
          awardMoney:
            rewardType === REWARD_MONEY
              ? transformServerAmount(award || 0)
              : undefined,
          mealticket:
            rewardType === REWARD_MEALTICKET
              ? transformServerAmount(award || 0)
              : undefined,
          tokens:
            rewardType === REWARD_TOKEN
              ? transformServerAmount(award || 0)
              : undefined,
          meetCondition,
          cappingCondition,
        }
      : ranking
      ? {
          rank: ranking,
          modeType: 1,
          rewardType,
          award: rewardType === REWARD_INTEGRAL ? award : undefined,
          products: productSpecId
            ? [
                {
                  productSpecId: productSpecId,
                  imgFile: {
                    uid: '',
                    url: transformUri(productUri || ''),
                    name: '',
                  },
                  name: productName,
                },
              ]
            : undefined,
          awardMoney:
            rewardType === REWARD_MONEY
              ? transformServerAmount(award || 0)
              : undefined,
          mealticket:
            rewardType === REWARD_MEALTICKET
              ? transformServerAmount(award || 0)
              : undefined,
          tokens:
            rewardType === REWARD_TOKEN
              ? transformServerAmount(award || 0)
              : undefined,
          meetCondition,
          cappingCondition,
        }
      : {
          modeType: meetCondition ? 3 : 4,
          rewardType,
          award: rewardType === REWARD_INTEGRAL ? award : undefined,
          awardMoney:
            rewardType === REWARD_MONEY
              ? transformServerAmount(award || 0)
              : undefined,
          mealticket:
            rewardType === REWARD_MEALTICKET
              ? transformServerAmount(award || 0)
              : undefined,
          tokens:
            rewardType === REWARD_TOKEN
              ? transformServerAmount(award || 0)
              : undefined,
          meetCondition,
          cappingCondition,
          products: productSpecId
            ? [
                {
                  productSpecId: productSpecId,
                  imgFile: {
                    uid: '',
                    url: transformUri(productUri || ''),
                    name: '',
                  },
                  name: productName,
                },
              ]
            : undefined,
        };
  });

  return {
    id,
    name,
    lattices: val,
  };
};

export const transfromShovelRecordsPo = (
  po: ShovelRecordsPo
): ShovelRecords => {
  const { id, gameName, gameImageUrl, accountNo, nickName, num } = po;

  return {
    id,
    gameName,
    gameImageUrl: transformUri(gameImageUrl),
    accountNo,
    nickName,
    num,
  };
};

export const transformMealticketGameDTO = (
  gameDefinePo: AddOrUpdatePointsDrawDefineDTO
): PointsDrawDefine => {
  const {
    id,
    name,
    type: poType,
    documentId,
    uri,
    ticketPrice,
    productList = [],
    gameProductList = [],
    canUpdate,
    bigPrizeCount,
    smallPrizeCount,
    abandonState,
  } = gameDefinePo || {};

  const [type] = mealticketGameTypesOptions.filter(
    (item) => item.id === poType
  );

  return {
    id,
    name,
    type,
    imgFile: uri
      ? {
          uid: documentId || uri,
          url: transformUri(uri),
          name: documentId || uri,
        }
      : undefined,
    ticketPrice: transformServerAmount(ticketPrice || 0),
    canUpdate,
    products: productList.map((gameProductPo) =>
      transformPointsDrawDefineProduct(gameProductPo)
    ),
    bigPrizeCount,
    smallPrizeCount,
    gameproducts: gameProductList.map((gameProductPo) =>
      transformPointsDrawDefineProduct(gameProductPo)
    ),
    isAbandon: abandonState === '2' ? true : false, // 1未废弃  2废弃
    abandonState: abandonState === '2' ? '废弃' : '未废弃', // 1未废弃  2废弃
  };
};

export const transform2MealticketGameDTO = (
  gameDefine: PointsDrawDefine
): AddOrUpdatePointsDrawDefineDTO => {
  const {
    id,
    name,
    imgFile,
    type,
    ticketPrice,
    products = [],
    gameproducts = [],
    earningRate,
  } = gameDefine;

  const productList = products?.map((item) =>
    transform2PointsDrawDefineProduct(item, id)
  );
  const gameProductList = gameproducts?.map((item) =>
    transform2PointsDrawDefineProduct(item, id)
  );

  return {
    id,
    name,
    ticketPrice: transform2ServerAmount(ticketPrice || 0),
    documentId: imgFile?.uid,
    uri: imgFile?.url,
    type: type?.id,
    productList: type?.id === '14' ? productList : undefined,
    gameProductList:
      type?.id === '15' || type?.id === '16' ? gameProductList : undefined,
    earningRate,
  };
};

export const transfromProductEditRecordsPo = (
  po: ProductEditRecordsPo
): ProductEditRecords => {
  const {
    id,
    productSpecId,
    oldName,
    newName,
    oldPrice,
    newPrice,
    oldRecoverPrice,
    newRecoverPrice,
    createdBy,
    createdTime,
    updatedBy,
    updatedTime,
    gameDefineNameList,
    currentProductName,
  } = po;
  return {
    id,
    productSpecId,
    oldName,
    newName,
    oldPrice: oldPrice ? transformServerAmount(oldPrice) : undefined,
    newPrice: newPrice ? transformServerAmount(newPrice) : undefined,
    oldRecoverPrice: oldRecoverPrice
      ? transformServerAmount(oldRecoverPrice)
      : undefined,
    newRecoverPrice: newRecoverPrice
      ? transformServerAmount(newRecoverPrice)
      : undefined,
    createdBy,
    createdTime,
    updatedBy,
    updatedTime,
    gameDefineNameList: gameDefineNameList ? gameDefineNameList.join('\n') : '',
    currentProductName,
  };
};

export const transfromRelatedTemplate = (
  po: RelatedTemplatPo
): RelatedTemplatPo => {
  const {
    id,
    name,
    type,
    ticketPrice,
    totalCount,
    earningRate,
    bigPrizeCount,
    smallPrizeCount,
    uri,
    abandonState,
    documentId,
    price,
  } = po;
  const isIntegral = IntegralTypeOption.some((item) => item.id === type);
  const isMealticket = MealticketTypeOptions.some((item) => item.id === type);

  return {
    isIntegral,
    isMealticket,
    id,
    name,
    type,
    ticketPrice:
      isIntegral || isMealticket
        ? price || ticketPrice || 0
        : transformServerAmount(price || ticketPrice || 0),
    totalCount,
    earningRate,
    bigPrizeCount,
    smallPrizeCount,
    documentId,
    uri: transformUri(uri),
    abandonState: abandonState === '2' ? '废弃' : '未废弃', // 1未废弃  2废弃
  };
};

export const transformAllConditiondDTO = (
  gameDefine: AllConditionDTO
): AllConditionDTO => {
  const {
    id,
    configName,
    configType,
    configTypeDesc,
    createdBy,
    detailCount,
    gradeCondition,
    pkType,
  } = gameDefine;

  return {
    id,
    configName,
    configType,
    configTypeDesc,
    createdBy,
    detailCount,
    gradeCondition,
    pkType,
  };
};

export const transformConditiondDefineDTO = (
  po: ConditionDefineDTO
): ConditionDefineDTO => {
  const {
    id,
    configName,
    configType,
    productList,
    pkType,
    pkValue,
    gradeCondition,
  } = po;

  return {
    imgFile:pkValue ? {
      url:transformUri(pkValue as string || ''),
      name:'',
      uid:'',
    } : '',
    id,
    configName,
    configType,
    pkType,
    pkValue,
    gradeCondition,
    productList:
      productList &&
      productList.map((gameProductPo) =>
        transformConditiondproductList(gameProductPo)
      ),
  };
};

export const transformConditiondproductList = (
  gameProductPo: ConditiondproductList
): ConditiondproductList => {
  const { uri, productSpecId, recoverPrice, name, productId } = gameProductPo;

  return {
    id: productSpecId,
    name,
    imgFile: uri
      ? { uid: productId, url: transformUri(uri), name: productId }
      : undefined,
    productSpecId: productSpecId,
    recoverPrice: transform2ServerProbability(recoverPrice || 0),
  };
};

export const transformPagodaGame = (
  po: AddPagodaDrawDefineDTO
): AddPagodaReward => {
  const {
    id,
    name,
    ticketPrice,
    documentId,
    uri,
    towerGameDefineRelDTOList,
    type,
    autoUpAndDown,
  } = po;

  const groups = towerGameDefineRelDTOList?.reduce<TowerGameDefineRelDTO[][]>(
    (ini, product) => {
      const [targetGroup] = ini.filter((grp) => {
        const [first] = grp;
        return first.index === product.index;
      });

      if (targetGroup) targetGroup.push(product);
      else ini.push([product]);
      return ini;
    },
    []
  );
  const val: PagodaRewards[] = groups!.map((item) => {
    const [first] = item;

    const {
      towerFloorNum,
      award,
      rewardType,
      towerFloorDefineId,
      towerFloorDefineUri,
      towerFloorDefineName,
      awardProductSpecId,
      awardProductSpecUri,
      awardProductSpecName,
      limitNum,
    } = first;

    return item.length > 1
      ? {
          limitNum,
          modeType: 2,
          start: towerFloorNum,
          end: (towerFloorNum || 0) + item.length - 1,
          rewardType: item[1].rewardType,
          award:
            item[1].rewardType === REWARD_INTEGRAL ? item[1].award : undefined,
          products: item[1].awardProductSpecId
            ? [
                {
                  productSpecId: item[1].awardProductSpecId,
                  imgFile: {
                    uid: item[1].awardProductSpecId,
                    url: transformUri(item[1].awardProductSpecUri || ''),
                    name: item[1].awardProductSpecId,
                  },
                  name: item[1].awardProductSpecName,
                },
              ]
            : undefined,
          towerFloorDefines: towerFloorDefineId
            ? [
                {
                  productSpecId: towerFloorDefineId,
                  imgFile: {
                    uid: towerFloorDefineId,
                    url: transformUri(towerFloorDefineUri || ''),
                    name: towerFloorDefineId,
                  },
                  name: towerFloorDefineName,
                },
              ]
            : undefined,
          awardMoney: [2, 6].includes(item[1].rewardType)
            ? transformServerAmount(item[1].award || 0)
            : undefined,
          mealticket:
            item[1].rewardType === REWARD_MEALTICKET
              ? transformServerAmount(item[1].award || 0)
              : undefined,
        }
      : {
          limitNum,
          towerFloorNum,
          modeType: 1,
          rewardType,
          award: rewardType === REWARD_INTEGRAL ? award : undefined,
          products: awardProductSpecId
            ? [
                {
                  productSpecId: awardProductSpecId,
                  imgFile: {
                    uid: awardProductSpecId,
                    url: transformUri(awardProductSpecUri || ''),
                    name: awardProductSpecId,
                  },
                  name: awardProductSpecName,
                },
              ]
            : undefined,
          towerFloorDefines: towerFloorDefineId
            ? [
                {
                  productSpecId: towerFloorDefineId,
                  imgFile: {
                    uid: towerFloorDefineId,
                    url: transformUri(towerFloorDefineUri || ''),
                    name: towerFloorDefineId,
                  },
                  name: towerFloorDefineName,
                },
              ]
            : undefined,
          awardMoney: [2, 6].includes(rewardType)
            ? transformServerAmount(award || 0)
            : undefined,
          mealticket:
            rewardType === REWARD_MEALTICKET
              ? transformServerAmount(award || 0)
              : undefined,
        };
  });
  return {
    id,
    name,
    typeId: type ? type : 18,
    ticketPrice: ticketPrice ? transformServerAmount(ticketPrice) : undefined,
    imgFile: {
      uid: documentId || '',
      url: transformUri(uri || ''),
      name: documentId || '',
    },
    towerGameDefineRelDTOList: val,
    autoUpAndDown,
  };
};

export const transform2PagodaGame = (
  gameDefine: AddPagodaReward
): AddPagodaDrawDefineDTO => {
  const {
    id,
    name,
    ticketPrice,
    imgFile,
    towerGameDefineRelDTOList,
    typeId,
    autoUpAndDown,
  } = gameDefine;

  return {
    id,
    name,
    type: typeId,
    ticketPrice: ticketPrice ? transform2ServerAmount(ticketPrice) : undefined,
    documentId: imgFile?.uid,
    uri: imgFile?.url,
    towerGameDefineRelDTOList: towerGameDefineRelDTOList
      ? transform2PagodaTowerGame(towerGameDefineRelDTOList)
      : undefined,
    autoUpAndDown,
  };
};
export const transform2AddPagodaGame = (
  gameDefine: AddPagodaReward
): AddPagodaDrawDefineDTO => {
  const { id, rank, towerGameDefineRelDTOList } = gameDefine;

  return {
    id,
    appendTowerGameDefineRelDTOList: towerGameDefineRelDTOList
      ? transform2PagodaTowerGame(towerGameDefineRelDTOList, rank)
      : undefined,
  };
};
export const transform2PagodaTowerGame = (
  po: PagodaRewards[],
  rank?: number
): TowerGameDefineRelDTO[] => {
  let res: TowerGameDefineRelDTO[] = [];
  po.forEach((item, index) => {
    const {
      modeType,
      towerFloorNum,
      rewardType,
      award,
      start,
      end,
      products,
      awardMoney,
      mealticket,
      towerFloorDefines,
      id,
      limitNum,
    } = item;

    if (modeType === MODE_SPECIFIC) {
      res.push({
        id,
        towerFloorNum,
        limitNum,
        rewardType,
        towerFloorDefineId: (towerFloorDefines || [])[0].productSpecId,
        awardProductSpecId:
          rewardType === REWARD_COMMODITY
            ? (products || [])[0].productSpecId
            : undefined,
        index: rank ? rank + index : index,
        award:
          rewardType === REWARD_INTEGRAL
            ? award
            : rewardType === REWARD_MEALTICKET
            ? transform2ServerAmount(mealticket || 0)
            : transform2ServerAmount(awardMoney || 0),
      });
    } else {
      for (let i = start || 0; i <= (end || 0); i++) {
        res.push({
          id,
          towerFloorNum: i,
          limitNum,
          rewardType: i > 1 ? rewardType : undefined,
          towerFloorDefineId: (towerFloorDefines || [])[0].productSpecId,
          awardProductSpecId:
            rewardType === REWARD_COMMODITY && i > 1
              ? (products || [])[0].productSpecId
              : undefined,
          index: rank ? rank + index : index,
          award:
            rewardType === REWARD_INTEGRAL && i > 1
              ? award
              : rewardType === REWARD_MEALTICKET
              ? transform2ServerAmount(mealticket || 0)
              : transform2ServerAmount(awardMoney || 0),
        });
      }
    }
  });
  return res;
};

export const transformStockLisDefineDTO = (
  po: StockListDefine
): StockListDefine => {
  const { id, productBarCode, totalNum, addressList, productName } = po;
  return {
    productName,
    id,
    productBarCode,
    totalNum,
    addressList: addressList?.map((item) => transformAddressList(item)),
  };
};
export const transformAddressList = (po: AddressList): AddressList => {
  const { id, addrNum, stockAddress } = po;
  return {
    id,
    addrNum,
    stockAddress,
  };
};

export const transformStockRecordDTO = (
  po: StockRecordDefine
): StockRecordDefine => {
  const {
    id,
    stockType,
    operateNum,
    operateName,
    createdTime,
    productName,
    stockAddress,
  } = po;
  return {
    id,
    operateNum,
    operateName,
    stockType,
    createdTime,
    productName,
    stockAddress,
  };
};

export const transformCardProduct = (data: ProductSpecDTO): any[] => {
  const { productSpecId, uri, name, price } = data;
  return [
    {
      productSpecId,
      uri: transformUri(uri || ''),
      name,
      price,
    },
  ];
};

export const tranformCardRewardListItem = (
  data: RewardListItem
): tranformRewardList => {
  const {
    rewardType,
    rewardValue,
    orderNum,
    productName,
    productUri,
    productPrice,
  } = data;

  let productData: ProductSpecDTO;
  productData = {
    productSpecId: rewardValue,
    uri: productUri,
    name: productName,
    price: productPrice,
  };

  return {
    rewardType,
    rewardValue,
    orderNum,
    product: rewardType === '3' ? transformCardProduct(productData) : undefined,
  };
};

export const transformCardDetailList = (listItem: CardDetailListItem): any => {
  const { sequence, rewardList } = listItem;
  return {
    sequence,
    rewardList: (rewardList || []).map((item) =>
      tranformCardRewardListItem(item)
    ),
  };
};

export const transformWelfareCardList = (
  item: CardData
): tranformCardListItem => {
  const {
    id,
    cardAmount,
    cardDetailList,
    cardType,
    totalCount,
    status,
    limitStartTime,
    limitEndTime,
    cardName,
    experience,
    delFlag,
    buyWay,
  } = item;

  return {
    delFlag: delFlag === 1 ? true : false,
    cardName,
    experience,
    limitTime:
      limitStartTime && limitEndTime
        ? [limitStartTime, limitEndTime]
        : undefined,
    id,
    cardAmount,
    cardType,
    status: status === 1 ? true : false, //1上架，2:下架
    totalCount,
    cardDetailList: (cardDetailList || []).map((item) =>
      transformCardDetailList(item)
    ),
    buyWay,
  };
};

export const tranformUpdateCardData = (
  data: tranformCardListItem
): CardData => {
  const {
    id,
    cardAmount,
    cardType,
    cardDetailList,
    totalCount,
    status,
    limitTime,
    cardName,
    experience,
    buyWay,
  } = data;
  return {
    cardName,
    experience,
    limitStartTime: limitTime ? limitTime[0] : undefined,
    limitEndTime: limitTime ? limitTime[1] : undefined,
    id,
    cardAmount,
    cardType,
    totalCount,
    status: status ? 1 : 2,
    cardDetailList: (cardDetailList || []).map((item) =>
      transformUpdateCardDetailList(item)
    ),
    limitCount: limitTime ? 1 : undefined,
    buyWay,
  };
};

export const transformUpdateCardDetailList = (
  listItem: tranformCardDetailList
): CardDetailListItem => {
  const { sequence, rewardList } = listItem;
  return {
    sequence,
    rewardList: (rewardList || []).map((item) =>
      transformUpdateRewardList(item)
    ),
  };
};

export const transformUpdateRewardList = (
  listItem: tranformRewardList
): RewardListItem => {
  const { rewardType, rewardValue } = listItem;

  return {
    rewardType,
    rewardValue: Array.isArray(rewardValue)
      ? rewardValue[0]?.productSpecId
      : rewardValue,
    productName: Array.isArray(rewardValue) ? rewardValue[0]?.name : undefined,
    productPrice: Array.isArray(rewardValue)
      ? rewardValue[0]?.price
      : undefined,
    productUri: Array.isArray(rewardValue)
      ? rewardValue[0]?.imgFile?.url
      : undefined,
  };
};

const MAPPINVALUE: any = {
  '1': '积分',
  '2': '金币',
  '3': '商品',
  '4': '饭票',
  '6': '厨神币',
};

const MAPPINGSTATUS: any = {
  '1': '已领取',
  '2': '未领取',
  '3': '已失效',
};

const MAPPINGTYPE: any = {
  '1': '周卡',
  '2': '月卡',
  '3': '季卡',
};

const tranformRewardvalue = (val?: string, type?: string): string => {
  const text = type ? MAPPINVALUE[type] : undefined;
  return `${val + text}`;
};

export const transformWelfareCardReacordListItem = (
  listItem: WelfareCardRecordListItem
): TransformWelfareCardRecordListItem => {
  const {
    id,
    accountNo,
    cardId,
    cardName,
    cardType,
    drawStatus,
    endTime,
    startTime,
    nickName,
    productName,
    productUri,
    rewardType,
    rewardValue,
    experienceSum,
    buyTime,
  } = listItem;

  return {
    id,
    accountNo,
    cardId,
    cardName,
    cardType: cardType ? MAPPINGTYPE[cardType] : undefined,
    rewardType: rewardType ? MAPPINVALUE[rewardType] : undefined,
    product:
      rewardType === '3'
        ? { productName, productUri: transformUri(productUri || '') }
        : undefined,
    rewardValue:
      rewardType !== '3'
        ? tranformRewardvalue(rewardValue || '', rewardType || '')
        : undefined,
    startTime: startTime ? formatDate(startTime) : undefined,
    endTime: endTime ? formatDate(endTime) : undefined,
    nickName,
    experienceSum,
    drawStatus: drawStatus ? MAPPINGSTATUS[drawStatus] : undefined,
    buyTime: buyTime ? formatDate(buyTime) : undefined,
  };
};

export const transformCardName = (
  listItem: WelfareCardNameListItem
): OptionType => {
  const { cardId, cardName } = listItem;
  return {
    value: cardId,
    label: cardName,
  };
};

export const transformVipGradeListDTO = (po: VipGradeListDTO): VipGrade => {
  const {
    commentName,
    userId,
    uri,
    walletId,
    accountNo,
    balance,
    wechatId,
    nickName,
    wechatName,
    gradeType,
    gradeEndTime,
    operationTime,
    operator,
  } = po;
  return {
    commentName,
    avatar: uri,
    userId,
    walletId,
    accountNo,
    balance: transformServerAmount(balance),
    wechatId,
    nickName,
    wechatNickName: wechatName,
    gradeType,
    gradeEndTime,
    operationTime,
    operator,
  };
};

export const transformQueryActivityListParams = (
  params: TransformQueryActivityParams
): QueryActivityParams => {
  const { pageNo, pageSize, state, activityName, activityTime } = params;
  return {
    pageNo,
    pageSize,
    state,
    activityName,
    startTime: activityTime ? moment(activityTime[0]).valueOf() : undefined,
    endTime: activityTime ? moment(activityTime[1]).valueOf() : undefined,
  };
};

export const transformQueryActivityListItem = (
  po: QueryActivityListItem
): TransformQueryActivityListItem => {
  const {
    id,
    activityName,
    startDate,
    endDate,
    state,
    createdBy,
    awardNameList,
    createdTime,
  } = po;
  return {
    id,
    activityName,
    createdBy,
    // @ts-ignore
    state: state ? stateType[state] : '',
    createdTime: createdTime ? formatDate(createdTime) : '',
    startDate: startDate ? formatDate(startDate) : '',
    endDate: endDate ? formatDate(endDate) : '',
    awardNameList: awardNameList?.toString(),
    activityTime:
      startDate && endDate
        ? `${formatDate(startDate)} ~ ${formatDate(endDate)}`
        : '',
  };
};

export const transformQueryActivityByIdDetail = (
  po: QueryActivityByIdDetail
): TransformQueryActivityByIdDetail => {
  const {
    id,
    activityName,
    awardList,
    dtlDTOList,
    startDate,
    endDate,
    state,
    awards,
  } = po;
  return {
    id,
    activityName,
    awardList,
    activityTime:
      startDate && endDate
        ? [moment(startDate), moment(endDate)]
        : [null, null],
    state,
    dtlDTOList: dtlDTOList?.map((item) =>
      transformQueryActivityByIdDtlDTOListItem(item)
    ),
    awards,
  };
};

export const transformQueryActivityByIdDtlDTOListItem = (
  item: QueryActivityByIdDtlDTOListItem
): TransformQueryActivityByIdDtlDTOListItem => {
  const {
    activityId,
    cycleType,
    week,
    gameDefineId,
    gameDefineName,
    gameDefineUri,
    startRunTime,
    endRunTime,
    rewardType,
    rewardProb,
    appointProductSpec,
    appointProductSpecName,
    appointProductSpecUri,
    rewardValue,
    id,
  } = item;
  return {
    activityId,
    cycleType,
    week,
    rewardValue:
      rewardType && [5, 6, 7].includes(rewardType)
        ? (rewardValue || 0) / 100
        : rewardValue,
    rewardType,
    rewardProb: rewardProb ? Number((rewardProb * 100).toFixed(2)) : undefined,
    timeRange:
      startRunTime && endRunTime
        ? [moment(startRunTime, 'HH:mm:ss'), moment(endRunTime, 'HH:mm:ss')]
        : [null, null],
    configProducts: appointProductSpec
      ? [
          {
            productSpecId: appointProductSpec,
            name: appointProductSpecName,
            imgFile: {
              url: transformUri(appointProductSpecUri || ''),
            },
          },
        ]
      : [],
    id: gameDefineId,
    name: gameDefineName,
    imgFile: {
      url: transformUri(gameDefineUri || ''),
    },
    idDel: id,
  };
};

export const transformUpdateActivityData = (
  data: TransformQueryActivityByIdDetail
): QueryActivityByIdDetail => {
  const { id, activityName, activityTime, state, awardList, dtlDTOList } = data;
  return {
    id,
    activityName,
    awardList,
    state: state || 1,
    startDate: activityTime && moment(activityTime[0]).valueOf(),
    endDate: activityTime && moment(activityTime[1]).valueOf(),
    dtlDTOList: dtlDTOList?.map((item) =>
      transformUpdateActivityDataDtlDTOList(item)
    ),
  };
};

export const transformUpdateActivityDataDtlDTOList = (
  list: TransformQueryActivityByIdDtlDTOListItem
): QueryActivityByIdDtlDTOListItem => {
  const {
    activityId,
    cycleType,
    timeRange,
    week,
    rewardProb,
    rewardType,
    rewardValue,
    configProducts,
    id,
    name,
    imgFile,
    idDel,
  } = list;
  let data;
  if (configProducts?.length) {
    data = configProducts[0];
  }
  return {
    activityId,
    cycleType,
    week,
    idDel,
    gameDefineId: id,
    gameDefineName: name,
    gameDefineUri: imgFile?.url,
    startRunTime: timeRange && moment(timeRange[0]).format('HH:mm:ss'),
    endRunTime: timeRange && moment(timeRange[1]).format('HH:mm:ss'),
    rewardValue:
      rewardType && [5, 6, 7].includes(rewardType)
        ? (rewardValue || 0) * 100
        : rewardValue,
    rewardType,
    rewardProb: rewardProb ? rewardProb / 100 : undefined,
    appointProductSpec: data?.productSpecId,
    appointProductSpecName: data?.name,
    appointProductSpecUri: data?.imgFile?.url,
  };
};
export const transformUserGuideListDTO = (
  po: UserGuideListDTO
): UserGuideListDTO => {
  const { id, menuUrl, detailList } = po;
  return {
    id,
    menuUrl,
    detailList: detailList
      ? detailList.map((item) => transformUserGuideDetailList(item))
      : undefined,
  };
};
export const transformUserGuideDetailList = (
  po: UserGuidedetailList
): UserGuidedetailList => {
  const { id, orderNum, pictureDesc, documentId, uri, detailName } = po;
  return {
    id,
    orderNum,
    pictureDesc,
    imgFile: uri
      ? {
          uid: documentId || uri,
          url: transformUri(uri),
          name: documentId || uri,
        }
      : undefined,
    detailName,
  };
};

export const transform2InsertUserGuideDefineDTO = (
  po: UserGuideListDTO
): UserGuideListDTO => {
  const { id, menuUrl, detailList } = po;
  return {
    id: id ? id : undefined,
    menuUrl,
    detailList: detailList
      ? detailList.map((item, i) => transform2UserGuideDetailList(item, i))
      : undefined,
  };
};
export const transform2UserGuideDetailList = (
  po: UserGuidedetailList,
  i: number
): UserGuidedetailList => {
  const { orderNum, pictureDesc, imgFile, detailName } = po;
  return {
    orderNum: i,
    pictureDesc,
    documentId: imgFile?.uid,
    uri: imgFile?.url,
    detailName,
  };
};

export const transformArticleList = (po: ArticleData): ArticleData => {
  const { uriList } = po;
  return {
    ...po,
    uriList: uriList.map((item) => transformForumUri(item)),
    // uriList: uriList.map((item) => transformUri(item)),
  };
};

export const transform2SaveCubePoolDTO = (
  gameDefine: CubePoolsSepc
): SaveCubePoolDTO => {
  const { id, name, mainProducts = [], products = [],modeType,showFlag,limitType,limitNum,totalNum,redeemType,productSpecId,imgFile } = gameDefine;

  const relDTOList = products?.map((item) =>
    transform2SaveCubePoolProduct(item)
  );

  return {
    redeemType,
    modeType,
    showFlag,
    limitType,
    limitNum,
    totalNum,
    id,
    name,
    productSpecId:redeemType === 1 ? mainProducts[0].productSpecId || '' : transform2ServerAmount(productSpecId || 0),
    relDTOList,
    documentId:imgFile?.uid
  };
};

export const transform2SaveCubePoolProduct = (
  gameProduct: CubePoolProduct
): relDTO => {
  const { productSpecId, count } = gameProduct;

  return {
    productSpecId,
    num: count ? count : undefined,
  };
};

export const transformSaveCubePoolDTO = (
  gameDefinePo: SaveCubePoolDTO
): CubePoolsSepc => {
  const {
    id,
    name,
    productSpecId,
    relDTOList,
    uri,
    recoverPrice,
    price,
    productName,
    modeType,
    showFlag,
    limitType,
    limitNum,
    totalNum,
    documentId,
    redeemType,
  } = gameDefinePo || {};
  
  return {
    redeemType,
    modeType,
    showFlag,
    limitType,
    limitNum,
    totalNum,
    id,
    name,
    productSpecId: redeemType === 2 ?transformServerAmount(Number(productSpecId) || 0) : undefined ,
    imgFile: redeemType === 2 ? { uid: documentId || '', url: transformUri(uri || ''), name: '' } : undefined,
    mainProducts:
      productSpecId && uri && redeemType === 1
        ? [
            {
              name: productName,
              count: 1,
              recoverPrice: transformServerAmount(recoverPrice || 0),
              price: transformServerAmount(price || 0),
              productSpecId: productSpecId,
              imgFile: { uid: '', url: transformUri(uri || ''), name: '' },
            },
          ]
        : undefined,
    products: relDTOList?.map((gameProductPo) => {
      const {
        id,
        uri,
        name,
        productSpecId,
        recoverPrice,
        num,
        productName,
        price,
      } = gameProductPo;
      return {
        id,
        name: productName,
        imgFile: { uid: '', url: transformUri(uri || ''), name: '' },
        productSpecId: productSpecId,
        recoverPrice: transformServerAmount(recoverPrice || 0),
        price: transformServerAmount(price || 0),
        count: num,
      };
    }),
  };
};

export const transfromCobeCompoundListPo = (
  po: CobeCompoundListPo
): CobeCompoundListPo => {
  const {
    id,
    name,
    price,
    recoverPrice,
    productName,
    uri,
    redeemNum,
    totalNum,
    highlight,
    relDTOList,
    num,
    limitNum,
    limitType,
    modeType,
    recordsNum,
    productSpecId,
  } = po;
  return {
    resultNum: redeemNum ? 1 : 0,
    productSpecId,
    recordsNum:recordsNum ? recordsNum : 0,
    limitNum,
    limitType,
    modeType,
    id,
    name,
    price: transformServerAmount(price || 0),
    recoverPrice: transformServerAmount(recoverPrice || 0),
    productName,
    highlight,
    uri: transformUri(uri),
    redeemNum,
    totalNum,
    num :num ? num : 0,
    relDTOList: relDTOList
      ? relDTOList.map((item) => transfromCobeCompoundListPo(item))
      : [],
  };
};

export const transfromCobeRecordListPo = (po: CubeRecordDTO): CubeRecordDTO => {
  const {
    id,
    redeemConfigName,
    accountNo,
    productName,
    uri,
    price,
    recoverPrice,
    redeemOperateName,
    redeemTime,
    delFlag,
    redeemNum
  } = po;
  return {
    redeemNum,
    delFlag: delFlag === 0 ? '已删除' : '使用中',
    id,
    redeemConfigName,
    accountNo,
    productName,
    uri: transformUri(uri),
    price: transformServerAmount(price || 0),
    recoverPrice: transformServerAmount(recoverPrice || 0),
    redeemOperateName,
    redeemTime,
  };
};

export const transfromCobeRecordDetailsPo = (po: relDTO): relDTO => {
  const { productName, productSpecId, uri, price, recoverPrice, num } = po;
  return {
    productName,
    productSpecId,
    uri: transformUri(uri || ''),
    price: transformServerAmount(price || 0),
    recoverPrice: transformServerAmount(recoverPrice || 0),
    num,
  };
};
export const transformOrderDetailList = (
  po: ApplyForDeliveryPo
): OrderDetail => {
  const { productName, count } = po;
  return {
    productName,
    productCount: count,
  };
};
export const transformBigPrizeListItem = (po: any): BigPrizeListItem => {
  const { infoCode, infoDesc } = po;
  return {
    categoryId: infoCode,
    label: infoDesc,
  };
};
export const TranformTowerRecordListItem = (
  item: TowerFloorRecordList
): TowerFloorRecordList => {
  return {
    ...item,
  };
};
export const transformAddressPo = (
  addressPo: AddressPo
): AdministrativeDivisionItem => {
  const {
    id,
    parentId,
    level, // 应该是number类型
    name,
  } = addressPo;
  return {
    // prefix, // 后端缺
    name,
    code: id,
    value: id,
    label: name,
    isLeaf: false,
    parentId: Number(parentId),
  };
};

export const transformProductImgDetails = (
  po: productImgDetailsPo,
) => {
  const {uri, detailImgUrlList} = po;
  return {
    ...po,
    uri: transformUri(uri),
    originalUri: uri,
    detailImgUrlList: detailImgUrlList?.map((item) => {
      return {
       ...item,
        uri: transformUri(item.uri),
        originalUri: item.uri,
      }
    })
  }
}

export const transformHiddenPostConfig = (
  po: hiddenPostConfigPo,
) => {
  const {hiddenPostConfigRelPoList, uri} = po;
  return {
    ...po,
    uri: transformUri(uri || ''),
    hiddenPostConfigRelPoList: hiddenPostConfigRelPoList?.map((item) => {
      const {smallestPrice, maximumPrice, probability} = item
      return {
       ...item,
       smallestPrice: smallestPrice ? transformServerAmount(smallestPrice) : undefined,
       maximumPrice: maximumPrice ? transformServerAmount(maximumPrice) : undefined,
       probability: probability ? transform2ServerAmount(probability): undefined,
      }
    })
  }
}

export const transformConfigGiven = (po: ConfigGivenPo) => {
  const {pkValue,configTypeDesc,pkType} = po
  return {
    ...po,
    pkValue: pkValue ? transformUri(pkValue) : undefined,
    imgFile: pkValue ? { uid: pkType, url: transformUri(pkValue), name: configTypeDesc } : undefined
  }
}