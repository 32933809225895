import { useEffect, } from 'react';
import './index.less'
import { queryLogin } from '../../api/index';
const logoImg = 'https://cdn.szconst.com/static/cook/logo.png'
function Login() {
  const login = async ()=>{
    const base64Css = 'LmltcG93ZXJCb3ggLnRpdGxlIHtkaXNwbGF5OiBub25lO30KLmltcG93ZXJCb3ggLnFyY29kZSB7d2lkdGg6IDE1MHB4O30KLmltcG93ZXJCb3ggLnN0YXR1cyB7Y29sb3I6ICMwMDA7fSA='
    await queryLogin().then((res)=>{
       var obj = new window.WxLogin({
        self_redirect:false,
        id:"login_container", 
        appid: res.appid, 
        scope: "snsapi_login", 
        redirect_uri: res.redirect_uri,
        state: "",
        style: "",
        href: "data:text/css;base64,"+base64Css
        })
    })
   }
   useEffect(()=>{
    login()
   },[])
  return (
    <div className="container-box">
    <div className="container">
      <img src={logoImg} className='container-logo'/>
    <div className="login-wrapper" >
      <div className='scancode-header'>
        <h2 className='scancode-title'>安全登录</h2>
        <div className="wordLine1">
          请打开 <span>微信</span>
        </div>
        <div className='wordLine2'>扫描二维码登录</div>
        
      </div>
        <div id='login_container'>
          <div className='ball'>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
    </div>
    
</div>
</div>
  )
}

export default Login